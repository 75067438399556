import React from 'react';
import { ProductsContext } from '../../layers/products';
import { AuthContext } from '../../layers/auth';
import Select from 'react-select'
import Paginator from '../paginator';
import lbanner from '../../lbanner.jpg';
import Proms from '../promotionCaro';
import Screen from '../screen';
import Product, { ListProduct } from './product';
    

const PaginationBar = (props) => {
    const { counts, currentQueryCount, categories, currentQuery, actions: { filtersExist, clearFilters, goToSection } } = React.useContext(ProductsContext);
    const { languageIsPt: pt } = React.useContext(AuthContext);
    if (counts === null) return null;

    return (
        <div className="pagination-bar">
                {(props.showSections && ('frozen' in categories || 'seasonal' in categories || 'catering' in categories)) ?
                <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                        <ul style={{listStyleType:'none', display:'flex', flexDirection:'row', alignItems:'center'}}>

                            {
                                ('frozen' in categories || 'seasonal' in categories || 'catering' in categories) && 
                                <li style={{cursor:'pointer', color:currentQuery.includeAmbient ? '#ff8402' : 'black',  fontWeight:currentQuery.includeAmbient ? 'bold' : 'normal'}} onClick={() => goToSection('ambient')} >{pt ? 'Ambiente' : 'AMBIENT'}</li>
                            }
                            
                            {'frozen' in categories && <li className="divide" />}
                            {'frozen' in categories && <li style={{cursor:'pointer', color:currentQuery.includeFrozen ? '#ff8402' : 'black', fontWeight:currentQuery.includeFrozen ? 'bold' : 'normal'}} onClick={() => goToSection('frozen')}>{pt ? 'Congelados' : 'FROZEN'}</li>}
                            
                            {'seasonal' in categories && <li className="divide" />}
                            {'seasonal' in categories && <li style={{cursor:'pointer', color:(currentQuery.includeSeasonal && !currentQuery.includeAmbient) ? '#ff8402' : 'black', fontWeight:(currentQuery.includeSeasonal && !currentQuery.includeAmbient) ? 'bold' : 'normal'}} onClick={() => goToSection('seasonal')}>{pt ? 'Sazonal' : 'SEASONAL'}</li>}

                            {'catering' in categories && <li className="divide" />}
                            {'catering' in categories && <li style={{cursor:'pointer', color:(currentQuery.includeCatering && !currentQuery.includeAmbient) ? '#ff8402' : 'black', fontWeight: (currentQuery.includeCatering && !currentQuery.includeAmbient) ? 'bold' : 'normal'}} onClick={() => goToSection('catering')}>{pt ? 'Catering' : 'CATERING'}</li>}
                        </ul>
                </div>
                : <span></span>}
                <div className='pages'>
                    {filtersExist() && <p onClick={clearFilters} className="resultsCount pageLink clearFilter" style={{color:"#FAC043", fontSize: '0.9em', fontWeight:'bold'}}>{pt ? 'Limpar filtros' : 'Clear Filters'}</p> }
                    <div className="space_if_phone"></div>
                    <Paginator />
                </div>
        </div>
    )
}

const Filters = props => {
    const { categoryTranslations: ptc, listView, currentQuery, brands, categories, section, actions: {setQuery, filtersExist, clearFilters, setCatOne, setCatTwo, setListView} } = React.useContext(ProductsContext)
    const { isLoggedIn, languageIsPt: pt } = React.useContext(AuthContext);

    const translateIfPt = ct => {
        if (pt === false) {
            return ct
        }
        const clean = cleanText(ct)
        if (!(clean in ptc)) return ct
        return ptc[clean]
    }

    return (
        <div className="store-filters">
            <p>{pt ? 'Filtrar Produtos' : 'Filter Products'}</p>
            {
                filtersExist() && 
                <div style={{padding: 5, alignSelf:'stretch', display:'flex'}}>
                    <span style={{flex: 1, cursor:'pointer', color:'#FAC043', border: 'solid 1px #FAC043', padding: 5, borderRadius: 2}} onClick={clearFilters}>{pt ? 'Repor' : 'Reset'}</span>
                </div>
            }

            <div style={{padding: 5}}>
                <div style={{alignSelf:'stretch', paddingBottom: 5, paddingTop: 5, display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                    <span style={{fontSize: '1em'}}>{pt ? 'Vista em Lista' : 'List View'}</span>
                    <input type="checkbox" defaultChecked={listView} onChange={e => setListView(e.target.checked)} />
                </div>    
            </div>

            <div style={{padding: 5}}>
                <input 
                    id="search-filter" style={{padding: 6}} placeholder={pt ? 'Procurar' : "Search"} value={currentQuery.searchTerm || ''} onChange={e => setQuery({searchTerm:e.target.value})} />
            </div>

            <div style={{padding:5}}>
                <Select
                    isMulti
                    placeholder={pt ? 'Marcas' : "Brands"}
                    value={currentQuery.brands.map((brand, i) => ({value: brand, label: brand}))}
                    onChange={brands => setQuery({brands: brands.map(b => b.value)})}
                    options={brands.map((b) => ({value: b, label: b}))} />
            </div>

            <div className="hdiv" />

            <div style={{padding: 5}}>
            <div style={{alignSelf:'stretch', paddingBottom: 5, paddingTop: 5, display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                    <span style={{fontSize: '1em'}}>{pt ? 'In Stock' : 'In Stock'}</span>
                    <input type="checkbox" defaultChecked={false} onChange={e => setQuery({stockOnly:  e.target.checked})} />
                </div>
                <div style={{alignSelf:'stretch', paddingBottom: 5, paddingTop: 5, display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                    <span style={{fontSize: '1em'}}>{pt ? 'Promoções' : 'Promotions'}</span>
                    <input type="checkbox" defaultChecked={false} onChange={e => setQuery({promotionsOnly:  e.target.checked})} />
                </div>
                {isLoggedIn &&
                    <div style={{alignSelf:'stretch', paddingBottom:5, paddingTop: 5, display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                        <span style={{fontSize: '1em'}}>{pt ? 'Favoritos' : 'Favourites'}</span>
                        <input type="checkbox" checked={currentQuery.favesOnly} onChange={e => setQuery({favesOnly:  e.target.checked})} />
                    </div>
                }
            </div>

            <div className="hdiv" />
            {
            section in categories && 
                Object.keys(categories[section]).sort((a,b) => a < b ? 0 : 1).map((categoryOne, i) => {
                    return (
                        <div key={i} style={{padding: 5, display: 'flex', flexDirection:'column'}}>
                            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', fontSize: '1em'}}>
                                <span style={{textAlign:'left'}}>{translateIfPt(categoryOne)}</span>
                                <input 
                                    checked={(categoryOne in currentQuery.categories && currentQuery.categories[categoryOne].length === 0)} 
                                    onChange={e => setCatOne(categoryOne)} type="checkbox" />
                            </div>

                            <div style={{display:'flex', flexDirection:'column'}}>
                                {
                                    categories[section][categoryOne].map((catTwo, j) => {
                                        return (
                                            <div key={j} style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', paddingLeft: 12, fontSize: '1em'}}>
                                                <span style={{textAlign: 'left', margin: 4, marginLeft: 20, fontSize: '1em'}}>{translateIfPt(catTwo)}</span>
                                                <input onChange={e => setCatTwo(categoryOne, catTwo)} checked={categoryOne in currentQuery.categories && (currentQuery.categories[categoryOne].length === 0 || currentQuery.categories[categoryOne].indexOf(catTwo) !== -1)} type="checkbox" />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )

                })
            }

        </div>
    )

}

const cleanText = txt => {
    return txt.replaceAll(" ","").toLowerCase()
}

const Shop = props => {
    const { listView, currentQueryIDs, productData } = React.useContext(ProductsContext)
    const { actions  } = React.useContext(AuthContext);
    return (
        <Screen>
                {false && <img alt="banner" src={lbanner} width="100%" />}
                    <Proms small />
                <PaginationBar showSections />
                <div style={{alignSelf:'stretch', flexDirection:'row', display:'flex'}}>
                    <Filters />
                        {
                            listView ? 
                            <div className="list-view">
                                {
                                currentQueryIDs.map((productId, index) => {
                                        return <ListProduct np={() => actions.setLoginModalOpen(true)} product={productData[productId] || null} key={index} />
                                })
                            }
                            </div>
                            :
                            <div style={{alignSelf:'stretch', display:'flex', flexDirection:'column', alignItems:'center'}}>
                                <div className="grid">
                                    {
                                        currentQueryIDs.map((productId, index) => {
                                            return (
                                                <Product np={() => actions.setLoginModalOpen(true)} product={productData[productId] || null} key={index} />
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        }
                </div>
                <PaginationBar />
        </Screen>
    )
}

export default Shop;