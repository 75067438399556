import React from 'react';
import { AuthContext } from '../auth';
import { BasketContext } from '../basket';
import * as Api from '../../api';
import { RepContext } from '../rep';
import OrderModal from './viewOrderModal';
import UploadOrderModal from './uploadOrderModal';
import { downloadFile } from '../../utils';
import moment from 'moment';

export const OrdersContext = React.createContext();

const OrdersWrapper = props => {
    const authContext = React.useContext(AuthContext)
    const basketContext = React.useContext(BasketContext);
    const repContext = React.useContext(RepContext)
    return (
        <OrdersLayer authContext={authContext} basketContext={basketContext} repContext={repContext}>
            {props.children}
        </OrdersLayer>
    )
}

class OrdersLayer extends React.Component {
    state = {
        orders: {},
        cOrders: {},
        orderIds: [],
        currentQuery: {
            ids: [],
            userIds: [],
            offset: 0,
            limit: 20,
            upfront: true
        },
        currentQueryCount: 0,
        viewingOrder: null,
        uploadingOrder: false
    }

    getPlof = async () => {
        const data = await Api.Get("/orders/plof", false, this.props.repContext.behalfOf);
        if (data.url) {
            downloadFile('PLOF ' + moment().format("DD-MM-YYYY") + ".xlsx", data.url);
        }
    }

    setUploadingOrder = uploadingOrder => {
        this.setState({uploadingOrder})
    }

    setViewingOrder = order => {
        this.setState({viewingOrder: order})
    }

    componentDidUpdate(prevprops) {
        if (this.props.repContext.behalfOf !== prevprops.repContext.behalfOf) {
            this.changeBehalf();
        }
        if (this.props.authContext.isLoggedIn === false && prevprops.authContext.isLoggedIn === true) {
            this.setState({orders: {}, cOrders: {}, orderIds: [], currentQuery: {ids: [], userIds: [], offset: 0, limit: 20, upfront: true}, currentQueryCount: 0, viewingOrder: null});
        }
    }

    changeBehalf = () => {
        this.setState({orderIds: [], currentQuery: {ids: [], userIds: [], offset: 0, limit: 20, upfront: true}, currentQueryCount: 0, viewingOrder: null}, () => {
            this.getOrders();
        })
    }

    loadMoreOrders = async () => {
        this.setState({currentQuery: {...this.state.currentQuery, offset: this.state.orderIds.length}});
        this.getOrders();
    }

    getOrders = async () => {
        const data = await Api.Post("/orders/get", this.state.currentQuery, false, this.props.repContext.behalfOf);
        this.setState({currentQueryCount: data.count, orderIds: this.state.orderIds.concat((data.ids || []).filter(x => this.state.orderIds.indexOf(x) === -1)), orders: {...this.state.orders, ...(data.orders || {})}, cOrders: {...this.state.cOrders, ...(data.custOrders || {})}});
    }

    placeOrder = async (reference, callback=() => {}) => {
        const order = await Api.Post("/order/place", {reference}, false, this.props.repContext.behalfOf);
        this.setState({
            cOrders: {...this.state.cOrders, [order.id]: order},
            orderIds: [order.id].concat(this.state.orderIds),
            currentQueryCount: this.state.currentQueryCount + 1
        });
        this.props.basketContext.actions.refreshBasket();
        if (order.id) {
            callback(order.id);
        }
    }

    addOrder = async (order) => {
        this.setState({
            cOrders: {...this.state.cOrders, [order.id] : order},
            orderIds: [order.id].concat(this.state.orderIds),
            currentQueryCount: this.state.currentQueryCount + 1
        });
    }

    render() {
        return (
            <OrdersContext.Provider value={{
                ...this.state, 
                actions: {
                    placeOrder: this.placeOrder,
                    getOrders: this.getOrders,
                    setViewingOrder: this.setViewingOrder,
                    setUploadingOrder: this.setUploadingOrder,
                    getPlof: this.getPlof,
                    addOrder: this.addOrder
                }}}>
                {this.props.children}
                <OrderModal />
                <UploadOrderModal />
            </OrdersContext.Provider>
        )
    }
}

export default OrdersWrapper;