import React from 'react';
import { AuthContext } from '../auth';
import * as Api from '../../api';
import NoMoreModal from './modal';
import { RepContext } from '../rep';
import { ProductsContext } from '../products';
import BasketBar from './basketBar';

export const BasketContext = React.createContext();

const BasketWrapper = props => {
    const authContext = React.useContext(AuthContext);
    const repContext = React.useContext(RepContext);
    const productContext = React.useContext(ProductsContext);
    return (
        <BasketLayer productContext={productContext} repContext={repContext} authContext={authContext}>
            {props.children}
        </BasketLayer>
    )
}

class BasketLayer extends React.Component {
    state = {
        basket: {},
        noMoreOffer: null,
        noMoreQuantity: 0
    }

    getCount = () => {
        return Object.values(this.state.basket).reduce((a,b) => a + b.quantity,0);
    }

    getBasket = async () => {
        const b = await Api.Get("/basket", false, this.props.repContext.behalfOf);
        let basket = b
        if (typeof b === 'string') {
            basket = JSON.parse(b);
        }
        this.props.productContext.actions.getProductData(Object.values(basket).map(x => x.productId));
        this.setState({basket});
    }

    clearBasket = async (cb=() => {}) => {
        const n = await Api.Get("/basket/clear", false, this.props.repContext.behalfOf);
        this.setState({basket: {}}, cb);
    }

    addToBasket = async (offer, quantity, promotionId=null, override=null) => {
        if (offer.quantity > -1 && quantity > offer.quantity) {
            const currentQty = (this.state.basket[offer.id] || {quantity: 0}).quantity;
            this.setState({noMoreOffer: offer, noMoreQuantity: currentQty});
            return
        }

        const instr = {offerId: offer.id, quantity, promotionId, override};
        const basketProduct = await Api.Post("/basket/set", instr, false, this.props.repContext.behalfOf);
        this.setState({
            basket: {
                ...this.state.basket,
                [offer.id]: basketProduct
            }
        })
    }

    componentDidUpdate(prevprops, prevstate) {
        if (prevprops.authContext.isLoggedIn === false && this.props.authContext.isLoggedIn === true) {
            this.getBasket();
        }
        if (prevprops.repContext.behalfOf !== this.props.repContext.behalfOf) {
            this.getBasket();
        }
        if (prevprops.authContext.isLoggedIn === true && this.props.authContext.isLoggedIn === false) {
            this.setState({basket: {}});
        }
    }

    componentDidMount() {
        if (this.props.authContext.isLoggedIn === true) {
            this.getBasket();
        }
    }

    render() {
        return (
            <BasketContext.Provider value={{...this.state, actions: {refreshBasket: this.getBasket, setBasket: this.addToBasket, getCount: this.getCount, clear: this.clearBasket}}}>
                {this.props.children}
                <NoMoreModal 
                    basketQty={this.state.noMoreQuantity} 
                    offer={this.state.noMoreOffer} 
                    close={() => this.setState({noMoreOffer: null})} />
            </BasketContext.Provider>
        )
    }
}

export default BasketWrapper;