import React from 'react';


const Screen = ({title, children}) => {
    return (
        <div className="screen">
            {
                title && 
                <div className="store-center">
                    <div className="title-text">
                        {title.toUpperCase ? title.toUpperCase() : title}
                    </div>
                </div>
            }
            <div className="store-center">
                {children}
            </div>
        </div>
    )
}

export default Screen