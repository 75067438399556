import React, { useContext } from 'react';
import Modal from 'react-modal';
import { ProductsContext } from '../products';
import { OrdersContext } from './index.js';
import { modalStyle } from '../modalStyle';
import { AuthContext } from '../auth';
import axios from 'axios';
import * as Api from '../../api';
import { RepContext } from '../rep';
import { downloadFile } from '../../utils';

const ModalContent = props => {
    const { uploadingOrder, actions: { setUploadingOrder, getPlof, addOrder }} = useContext(OrdersContext);
    const { behalfOf } = React.useContext(RepContext);
    const { productData, actions: { getProductData } } = useContext(ProductsContext);
    const [file, setFile] = React.useState(null)
    const { languageIsPt: pt } = React.useContext(AuthContext);
    const [fatalError, setFatalError] = React.useState(null);
    const [message, setMessage] = React.useState(null);

    const handleFile = (e) => {
        setFatalError(null);
        if (e.target.files.length > 0) {
            setFile(e.target.files[0]);
        }
    }

    const process = async () => {
        if (file === null) return;
        if (fatalError) return;
        
        const fd = new FormData();
        fd.append("file", file);
        const data = await axios.post(Api.apiUrl+"/orders/upload", fd, {withCredentials: true, headers:{'Content-Type': 'multipart/form-data', 'X-Behalf': behalfOf}});
        const { url, fatalError: fatalErr, order, lineErrors } = data.data;

        if (fatalErr) {
            setFatalError(fatalErr);
        } else {
            if (order === null) {
                setFatalError("No Order Was placed - please review the returned file and check for errors")
            } else {
                if (lineErrors > 0) {
                    setMessage(`Order ${order.id} placed, with ${lineErrors} lines skipped. Please check the returned file for more information`)
                } else {
                    setMessage(`Order ${order.id} placed, with no errors`);
                }
                addOrder(order)
            }
        }
        if (url) {
            downloadFile('Order Upload Results.xlsx', url);
        }
        setFile(null);
    }

    return (
        <div id="uploadOrderModal" style={{display:'flex', flexDirection:'column'}}>
            <h2>{pt ? 'Carregar encomenda' : 'Upload an Order'}</h2>
            <div style={{alignSelf:'stretch', display:'flex', flexDirection:'column'}}>
                <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', padding: 20, borderBottom:'solid 1px grey'}}>
                    <span style={{marginRight:20}}>{pt ? 'Descarregar lista de produtos' : 'Download Price List'}</span>
                    <div onClick={getPlof} className='button'>Download</div>
                </div>
                <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', padding: 20, borderBottom:'solid 1px grey'}}>
                    <span style={{marginRight:20}}>{pt ? 'Carregar encomenda' : 'Upload an Order'}</span>
                    <input onChange={handleFile} type="file" id="upload-offer-select" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                </div>
            </div>
            {
                fatalError !== null && 
                <div style={{alignSelf:'stretch', display:'flex', flexDirection:'column', padding: 20, color:'red'}}>
                    {fatalError}
                </div>
            }
            {
                message !== null &&
                <div style={{alignSelf:'stretch', display:'flex', flexDirection:'column', padding: 20}}>
                    <span>{message}</span>
                </div>
            }
            <div style={{alignSelf:'stretch', flexDirection:'row', justifyContent:'space-between', alignItems:'center', padding: 20, display:'flex'}}>
                <div onClick={() => setUploadingOrder(false)} className='button cancel'>{pt ? 'Cancelar' : 'Cancel'}</div>
                <div onClick={() => process()} className={`button ${file === null || fatalError !== null ? 'cancel' : ''}`}>{pt ? 'Enviar' : 'Process'}</div>
            </div>
        </div>
    )
}

const UploadOrderModal = props => {
    const { uploadingOrder, actions: { setUploadingOrder }} = useContext(OrdersContext);

    return (
        <Modal
            isOpen={uploadingOrder}
            onRequestClose={() => setUploadingOrder(false)}
            style={modalStyle}
        >
            <ModalContent />
        </Modal>
    )
}

export default UploadOrderModal;