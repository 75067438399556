import React, { useEffect } from 'react';
import { BasketContext } from '../layers/basket';
import { ProductsContext } from '../layers/products';
import { AiFillPlusCircle, AiOutlineMinusCircle } from 'react-icons/ai';
import { OrdersContext } from '../layers/orders';
import { Redirect } from 'react-router';
import Screen from './screen';
import { Link } from 'react-router-dom';
import { AuthContext } from '../layers/auth';

const ProductInCheckout = props => {
    const { basketItem } = props;
    const { productData } = React.useContext(ProductsContext);
    const { languageIsPt: pt } = React.useContext(AuthContext);
    const { actions: {setBasket} } = React.useContext(BasketContext);
    const { isAdmin } = React.useContext(AuthContext);
    const [isSettingPrice, setIsSettingPrice] = React.useState(false);
    const [override, setOverride] = React.useState(basketItem.ov ? basketItem.ov.toFixed(2) : null);
    const [hasInit, setHasInit] = React.useState(false);

    const product = productData[basketItem.productId];

    const getOverride = (txt) => {
        if (txt === null || txt === '') {
            return null
        }
        let pts = txt.split('').filter(x => ['0','1','2','3','4','5','6','7','8','9','.'].indexOf(x) !== -1);
        const dotIndex = pts.indexOf('.');
        if (pts.length === 0) {
            return '';
        }
        if (dotIndex === -1) {
            return pts.join("")
        }

        const before = pts.slice(0, dotIndex).join('');
        const after = pts.slice(dotIndex).filter(x => x !== '.');
        while (after.length < 2) {
            after = after + "0";
            return before+ "." + after.join('');
        }
        return before + "." + after.slice(0,2).join('');
    }

    const submitOv = () => {
        let ov = null;
        if (override !== null) {
            ov = parseFloat(getOverride(override));
        }

        setBasket(product.offers.find(x => x.id === basketItem.offerId), basketItem.quantity, null, ov);
        setIsSettingPrice(false);
    }

    if (!product) return null;
    return (
        <div className="product-in-checkout" style={{color: "#666", alignSelf:'stretch', display:'flex', alignItems:'flex-start', flexDirection:'column', padding: 10, margin: 10, borderBottom: props.last ? 'none' : "solid 1px lightgrey"}}>
            
            <span style={{marginBottom: 10}}>{product.description} {product.size}</span>

            <div style={{display:'flex', flexDirection:'row', width:"40"}}>
                <div style={{width: 100, height: 100, marginRight: 20}}>
                    <img alt={product.description} src={product.image} />
                </div>
                <div style={{display: 'flex', flexDirection:'column', alignItems:'flex-start', marginLeft: 20}}>

                    {isSettingPrice ?
                        <div style={{padding: 5}}>
                            <input autoFocus onBlur={() => submitOv()} style={{width: 100}} onChange={e => setOverride(e.target.value.length > 0 ? e.target.value : null)} value={override} />
                        </div>
                        :
                            override !== null ? 
                                <span onDoubleClick={() => {
                                    if (isAdmin) {
                                        setIsSettingPrice(true);
                                    }
                                }} style={{fontWeight:'bold', color:'red'}}>Price: {override} €</span>
                            :
                                <span onDoubleClick={() => {
                                    if (isAdmin) {
                                        setIsSettingPrice(true);
                                    }
                                }}>Price:    {basketItem.price.toLocaleString('pt-PT', {style:'currency', currency:'EUR'})}</span> 
                    }
                    
                    <div style={{alignSelf:'stretch', paddingTop: 3, paddingBottom: 3, backgroundColor: 'white', marginTop: 10, display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', paddingLeft: 5, paddingRight: 5}}>  
                        <div onClick={() => setBasket(product.offers.find(x => x.id === basketItem.offerId), basketItem.quantity-1)} style={{cursor:'pointer'}}><AiOutlineMinusCircle color="#FAC043" size={22} /></div>
                        {basketItem.quantity} {pt ? 'No cesto' : 'in basket'}
                        <div onClick={() => setBasket(product.offers.find(x => x.id === basketItem.offerId), basketItem.quantity+1)} style={{cursor: 'pointer'}}><AiFillPlusCircle size={22} color="#FAC043" /></div>
                    </div>
                        <span style={{fontWeight:'bold', marginTop: 10}}>Total: {((override ? override : basketItem.price) * basketItem.quantity).toLocaleString('pt-PT', {style:'currency', currency:'EUR'})}</span>
                </div>
            </div>
        </div>
    )
}

const CheckoutScreen = props => {
    const { basket, actions: { getCount, clear, setOverride } } = React.useContext(BasketContext);
    const { actions: { getProductData } } = React.useContext(ProductsContext);
    const { actions : { placeOrder }} = React.useContext(OrdersContext)
    const { languageIsPt: pt } = React.useContext(AuthContext);
    const [reference, setReference] = React.useState('');
    const [didPlace, setDidPlace] = React.useState(false);
    const [didClear, setDidClear] = React.useState(false);

    const clearBasket = () => {
        clear(() => setDidClear(true));
    }

    React.useEffect(() => {
        const productIDs = Object.values(basket).filter(x => x.quantity > 0).map(x => x.productId);
        getProductData(productIDs);
    }, [basket, getProductData])

    if (didPlace) {
        return <Redirect to={`/orders?thanks=${didPlace}`} />
    }
    if (didClear) {
        return <Redirect to="/shop" />
    }

    const items = Object.values(basket).filter(x => x.quantity > 0);
    return (
        <Screen title="Checkout">
            <div style={{alignSelf:'stretch', flexDirection:'row', display:'flex', flex: 1, flexWrap:'wrap'}}>
                <div style={{display:"flex", flexDirection:'column', flex: 1, minWidth: 350}}>
                    {
                        items.map((item, i) => {
                            return <ProductInCheckout override={(price) => setOverride(item, price)} last={i === items.length -1} basketItem={item} />
                        })
                    }
                    <div onDoubleClick={clearBasket} style={{alignSelf:'strech', padding: 5, backgroundColor:'red', color:'white', borderRadius: 3, margin: 20, paddingLeft: 10, paddingRight:10, cursor:'pointer'}}>
                        Double Click to Clear Basket
                    </div>
                </div>

                <div style={{flexDirection: 'column', display: 'flex', borderLeft: 'solid 1px lightgrey', alignItems:'center', flex: 2, width: '100%', minWidth: 350, paddingTop: 20, minHeight: 500}}>
                    {
                        getCount() > 0 ?
                        <div style={{position: items.length > 4 ?  'sticky' : 'relative', top: items.length > 4 ? 300 : 0}}>
                            <span style={{fontSize: 30, color:'#666', fontWeight:'bold'}}>{pt ? 'A sua encomenda' : 'Your Order'}</span>
                            <ul style={{display:'flex', flexDirection:'row', listStyleType: 'none', fontSize: 20, color:'#666'}}>
                                <li>{getCount()} {pt ? 'Produtos' : 'items'}</li>
                                <li className="divide" />
                                <li>{Object.values(basket).reduce((a,b) => a + (b.quantity * (b.override ?  b.override : b.price)), 0).toLocaleString('pt-PT', {style:'currency', currency:'EUR'})}</li>
                            </ul>

                            <input style={{padding: 5, fontSize: 16, color:'#666', width: 300}} placeholder="Enter Order Reference (Optional)" value={reference} onChange={e => setReference(e.target.value)} />

                            <div onClick={() => placeOrder(reference, (orderId) => setDidPlace(orderId))} style={{alignSelf:'strech', padding: 10, backgroundColor:'#ff8402', color:'white', borderRadius: 3, marginTop: 20, paddingLeft: 20, paddingRight:20, cursor:'pointer'}}>{pt ? 'Encomendar' : 'Place Order'}</div>
                        </div>
                        :
                        <div>
                            <span style={{fontSize: 30, color:'#666', fontWeight:'bold', marginBottom: 20}}>{pt ? 'O cesto está vazio' : 'Your basket is empty'}</span>
                            <Link to="/shop"><div className="button">{pt ? 'Loja' : 'Shop'}</div></Link>
                        </div>
                    }
                </div>
            </div>

        </Screen>
    )
}

export default CheckoutScreen;