import React from 'react';
import { PromotionsContext } from '../layers/promotions';
import Screen from './screen';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { ProductsContext } from '../layers/products';
import Select from 'react-select/async';
import * as Api from '../api';
import { FiPlus, FiMinus } from 'react-icons/fi';
import axios from 'axios';

const basePrice = product => {
    if (product.ox === null || product.ox.length === 0) return 0;
    return product.ox.find(x => x.index === 0).price;
}

const promotedPrice = (price, typ, value) => {
    switch(typ) {
        case "PERCENTAGE":
            return price * value;
        case "HARD_PRICE":
            return value;
        case "HARD_DISCOUNT":
            return price - value;
        default:
            return 0;
    }
}

function getRandomInt() {
    const min = 1;
    const max = 10000;
    return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
  }
  

const PromGroup = props => {
    const { productData, actions: {getProductData} } = React.useContext(ProductsContext)
    const { group } = props;
    const [discountType, setDiscountType] = React.useState(group.discountType);
    const [discountValue, setDiscountValue] = React.useState(group.discountValue);
    const [editingValue, setEditingValue] = React.useState(group.discountValue);
    const [productIds, setProductIds] = React.useState(group.productIds);
    const [invalidValue, setInvalidValue] = React.useState(false);
    const [addingProduct, setAddingProduct] = React.useState(false);
    const [selectingValue, setSelectingValue] = React.useState(null);

    const update = () => {
        const udGroup = {...group, discountValue, discountType, productIds}
        props.update(udGroup)
    }

    React.useEffect(() => {
        if (discountValue !== group.discountValue || discountType !== group.discountType || productIds !== group.productIds) {
            update()
        }
    },[discountType, discountValue, productIds, group.discountValue, group.discountType, group.productIds, group])


    const addproduct = () => {
        const id = selectingValue
        setProductIds(productIds.concat([id]))
        setSelectingValue(null);
        setAddingProduct(false);
        getProductData([id], true)
    }

    const removeProduct = (id) => {
        setProductIds(productIds.filter(x => x !== id))
    }

    const updateValue = () => {
        if (editingValue.split) {
            const asFloat = parseFloat(
                editingValue.split('').filter(x => ['0','1','2','3','4','5','6','7','8','9','.'].indexOf(x) !== -1).join(''));
            if (!isNaN(asFloat)) {
                setDiscountValue(asFloat);
                setEditingValue(asFloat.toFixed(2))
                setInvalidValue(false);
            } else {
                setInvalidValue(true);
            }
        }
    }

    const searchProducts = async (term, cb) => {
        const results = await Api.Get(`/repsearch?term=${term}`);
        cb(results.map(x => ({value: x.id, label: x.description})));
    }


    return (
        <div style={{alignSelf:'stretch', margin: 10, backgroundColor:'#FFDC96', borderRadius: 5, padding: 10, position:'relative'}}>
            <div onClick={props.delete} style={{position:'absolute', top: 10, right: 10, cursor:'pointer', color:'red'}}>Delete</div>
            {group.id === 0 ? 'New ' : ''}Group {group.id !== 0 ? group.id : ''}
            
            <div style={{display:'flex', flexDirection:'row'}}>
                <select value={discountType} onChange={e => setDiscountType(e.target.value)}>
                    <option value="HARD_PRICE">Hard Price</option>
                    <option value="HARD_DISCOUNT">Hard Discount</option>
                    <option value="PERCENTAGE">Percentage</option>
                </select>

                <input value={editingValue} onBlur={updateValue} onChange={e => setEditingValue(e.target.value)} style={{padding: 5, fontSize: 16, width: 50, border: invalidValue ? 'solid 1px red' : "none"}} />
            </div>

            <table style={{width: '100%'}}>
                <thead>
                    <tr>
                        <th>Product Id</th>
                        <th>Description</th>
                        <th>Size</th>
                        <th>Case</th>
                        <th>Ean</th>
                        <th>Base Price</th>
                        <th>Promoted Price</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        productIds.map((id, i) => {
                            const product = productData[id] || null;
                            if (product === null) {
                                return null;
                            }
                            return (
                                <tr>
                                    <td>{product.id}</td>
                                    <td style={{textAlign:'left'}}>{product.description}</td>
                                    <td>{product.size}</td>
                                    <td>{product.case}</td>
                                    <td>{product.ean}</td>
                                    <td>{basePrice(product).toLocaleString('pt-PT', {style:'currency', currency:'EUR'})}</td>
                                    <td>{promotedPrice(basePrice(product), discountType, discountValue).toLocaleString('pt-PT', {style:'currency', currency:'EUR'})}</td>
                                    <td><div onClick={() => removeProduct(product.id)} style={{color:'red', cursor:'pointer'}}>Remove</div></td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            {
                addingProduct ?
                <div style={{display:'flex', flexDirection:'row'}}>
                    <div style={{width: 400}}>
                        <Select
                        cacheOptions
                        loadOptions={searchProducts}
                        defaultOptions
                        onChange={e => setSelectingValue(e.value)}
                        />
                    </div>
                    {
                        selectingValue !== null &&
                        <div onClick={addproduct} style={{marginLeft: 8, marginRight: 8}} className="button">Save</div>
                    }
                    <div onClick={() => setAddingProduct(false)} className="button cancel">Cancel</div>
                </div>
                :
                <div onClick={() => setAddingProduct(true)} className="button">
                    Add Product
                </div>
            }
        </div>
    )
}

const Promotion = props => {
    const { promotion } = props;
    const { actions: {getProductData} } = React.useContext(ProductsContext)
    const { actions : {updatePromotion, pushPromotion}} = React.useContext(PromotionsContext);
    const [startDate, setStartDate] = React.useState(moment(promotion.start).toDate())
    const [endDate, setEndDate] = React.useState(moment(promotion.end).toDate())
    const [name, setName] = React.useState(promotion.name);
    const [editingName, setEditingName] = React.useState(false)
    const [isKilled, setIsKilled] = React.useState(promotion.killed);
    const [groups, setGroups] = React.useState(promotion.groups);
    const [groupsChanged, setGroupsChanged] = React.useState(false);
    const [tick, setTick] = React.useState(0);
    const [refreshImgs, setRefreshImgs] = React.useState(false);
    const [sortOrder, setSortOrder] = React.useState(promotion.sortOrder);

    const uploadImage = async (event, size) => {
        if (event.target.files.length > 0) {
            const image = event.target.files[0];
            const formData = new FormData();

            var fr = new FileReader();
            fr.onload = function() {
                if (size === 'large') {
                    document.getElementById(`${promotion.id}_largeimage`).src = fr.result;
                } else {
                    document.getElementById(`${promotion.id}_smallimage`).src = fr.result;
                }
            }
            fr.readAsDataURL(image)

            formData.append('image', image);
            formData.append('promId', promotion.id);
            formData.append('size', size)
            const data = await axios.post(Api.apiUrl + "/upload_prom_image", formData, {withCredentials: true, headers: {'Content-Type': "multipart/form-data"}});
            if (size === "small") {
                pushPromotion({...promotion, smImage: data.data.url})
            } else if (size === "large") {
                pushPromotion({...promotion, lgImage: data.data.url})
            }

            setRefreshImgs(true)
        }
    }

    React.useEffect(() => {
        if (refreshImgs) {
            setRefreshImgs(false);
        }
    }, [refreshImgs])

    const deleteGroup = (index) => {
        let grps = [];
        for (var i=0; i < groups.length; i++) {
            if (i !== index) {
                grps.push(groups[i])
            }
        }
        setGroups(grps);
        setGroupsChanged(true);
        setTick(tick + 1);
    }

    const addGroup = () => {
        setGroups(groups.concat([{discountType: 'PERCENTAGE', discountValue: 1.00, id: 0, productIds: []}]));
    }

    const update = () => {
        const updatedPromotion = {
            ...promotion,
            start: moment(startDate).format("YYYY-MM-DDTHH:mm:ssZ"),
            end: moment(endDate).format("YYYY-MM-DDTHH:mm:ssZ"),
            name,
            killed: isKilled,
            groups,
            sortOrder
        }
        updatePromotion(updatedPromotion)
        setGroupsChanged(false);
        if (props.cancel) {
            props.cancel();
        }
    }

    const replaceGroup = (group, index) => {
        let udGroups = groups;
        udGroups.splice(index, 1, group);
        setGroups(udGroups)
        setGroupsChanged(true);
    }

    const hasChanged = () => {
        return name !== promotion.name || !moment(promotion.start).isSame(moment(startDate), 'day') || !moment(promotion.end).isSame(moment(endDate), 'day') || isKilled !== promotion.killed || groupsChanged || sortOrder !== promotion.sortOrder;
    }

    React.useEffect(() => {
        getProductData(promotion.productIds)
    }, [getProductData, promotion.productIds])

    const order = up => {
        if (up) {
            if (sortOrder === null) {
                setSortOrder(1)
            } else {
                setSortOrder(sortOrder + 1);
            }
        } else {
            if (sortOrder === 1) {
                setSortOrder(null);
            } else {
                setSortOrder(sortOrder - 1);
            }
        }
    }

    return (
        <div style={{alignSelf:'stretch', display:'flex', padding: 10, borderRadius: 3, border: hasChanged() ? 'solid 2px #FAC043' : 'solid 1px lightgrey', margin: 10, flexDirection:'column', alignItems:'flex-start'}}>
            <div style={{display:'flex', flexDirection:'row', alignItems:'center', alignSelf:'stretch', justifyContent:'space-between'}}>
                {
                    !editingName ?
                        <h3 onClick={() => setEditingName(true)} style={{cursor:'pointer'}}>{name}</h3>
                    :
                        <input value={name} onBlur={() => setEditingName(false)} onChange={e => setName(e.target.value)} />
                }
                <div style={{padding: 10, display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', background:'rgb(200, 200, 200)', borderRadius: 10}}>
                    <span style={{marginRight:10}}>Sort (lower first)</span>
                    {sortOrder !== null && <div onClick={() => order(false)} style={{marginRight: 10, cursor:'pointer'}}><FiMinus size={20} /></div>}
                    {sortOrder === null ? "Any" : sortOrder}
                    <div onClick={() => order(true)} style={{marginLeft: 10, cursor:'pointer'}}><FiPlus size={20} /></div>
                </div>
            </div>
            <div style={{display:'flex', flexDirection:'row'}}>
                <div style={{display:'flex', flexDirection:'column', margin: 5}}>
                    <span>Large Banner</span>
                    <input onChange={e => uploadImage(e, "large")} accept="image/jpeg" name={`${promotion.id}_lg_pick`} id={`${promotion.id}_lg_pick`} type="file" style={{display:'none'}} />
                    <label for={`${promotion.id}_lg_pick`}>
                        {!refreshImgs && <img alt="Large Banner" src={promotion.lgImage} id={`${promotion.id}_largeimage`} style={{width: 200, cursor:'pointer'}} />}
                    </label>
                </div>
                <div style={{display:'flex', flexDirection:'column', margin: 5}}>
                    <span>Small Banner</span>
                    <input onChange={e => uploadImage(e, "small")} accept="image/jpeg" name={`${promotion.id}_sm_pick`} id={`${promotion.id}_sm_pick`} type="file" style={{display:'none'}} />
                    <label for={`${promotion.id}_sm_pick`}>
                        {!refreshImgs && <img alt="Small Banner" src={promotion.smImage} id={`${promotion.id}_smallimage`} style={{width: 200, cursor:'pointer'}} />}
                    </label>
                </div>
                <div style={{display:'flex', flexDirection:'column', margin: 5}}>
                    <span>Active Dates</span>
                    <span>Start</span>
                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />

                    <span>End</span>
                    <DatePicker selected={endDate} onChange={date => setEndDate(date)} />
                </div>
                <div style={{display:'flex', flexDirection:'column', margin: 5}}>
                    <span>Is Hidden?<input type="checkbox" checked={isKilled} onChange={e => setIsKilled(e.target.checked)} /> </span>
                </div>
            </div>

            {
                (groups || []).map((group, i) => {
                    return (
                        <PromGroup delete={() => deleteGroup(i)} update={(group) => replaceGroup(group, i)} group={group} key={`${i}+${tick}`} />
                    )
                })
            }

            <div onClick={() => addGroup()} className="button">Add Group</div>
            <div style={{alignSelf:'stretch', display: 'flex', flexDirection:"row", justifyContent:'flex-end'}}>
                {props.cancel && <div onClick={props.cancel} className="button cancel">Cancel</div>}
                {hasChanged() && <div onClick={update} className="button">Save</div>}
            </div>
        </div>   
        )
}

const PromotionsScreen = props => {
    const { promotions } = React.useContext(PromotionsContext);
    const [addingProm, setAddingProm] = React.useState(false)

    const blankProm = {
            name: "New Promotion",
            id: 0,
            groups:[],
            start: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
            end: moment().add(7, 'days').format("YYYY-MM-DDTHH:mm:ssZ"),
            lgImage: "",
            smImage: "",
            sortOrder: null,
            killed: false,
            productIds: []
    }

    return (
        <Screen title="Promotions">
            <div style={{display:'flex', flexDirection:'column', alignSelf:'stretch', flex: 1}}>
                {
                    !addingProm ?
                        <div onClick={() => setAddingProm(true)} className="button">Add Promotion</div>
                        :
                        <Promotion cancel={() => setAddingProm(false)} promotion={blankProm} />
                    }
                {
                    Object.keys(promotions).map((k,i) => <Promotion promotion={promotions[k]} key={i} />)
                }
            </div>
        </Screen>
    )
}

export default PromotionsScreen;