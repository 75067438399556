import moment from 'moment';
import React from 'react';
import { AuthContext } from '../layers/auth';
import { NewsContext } from '../layers/news';
import Screen from './screen';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import * as Api from '../api';
import { 
    Link,
    useHistory
} from 'react-router-dom';

export const NewsArticlePage = props => {
    const { activeArticle: article } = React.useContext(NewsContext);
    const { languageIsPt: pt } = React.useContext(AuthContext);
    const { goBack } = useHistory();

    return (
        <Screen title={pt ? article.ptTitle : article.title}>
            <div style={{flex:1, flexDirection:'column', display:'flex'}}>
                <div style={{alignSelf:'stretch', flexDirection:'row', justifyContent:'flex-start', marginBottom: 10}}>
                    <div onClick={goBack} style={{width: 100}} className="cas-button">
                        Back
                    </div>

                </div>

                <div className='article-page'>
                    <div style={{width: 280, display:'flex', flexDirection:'column', margin: 10}}>
                        {
                            article.imageData && article.imageData.map((img, i) => (
                                <div key={i} style={{borderRadius: 5, overflow: 'hidden', cursor:"pointer", position:'relative', width: 300}}>
                                    <img alt={img.url} style={{cursor:"pointer", objectFit:'contain', width: 300}} src={img.url} />
                                </div>
                            ))
                        }
                    </div>
                    <div style={{flex:1, padding: 20}}>
                        <p style={{textAlign:'left'}}>{pt ? article.ptBody : article.body}</p>
                    </div>
                </div>
            </div>

            
        </Screen>
    )
}

const ArticleImages = props => {
    const { imageData } = props
    if (!imageData || imageData.length === 0) return null;
    return (<img style={{width: "100%", objectFit:'cover'}} src={imageData[0].url} />)
}

export const NewsArticle = props => {
    const {article} = props
    const { actions : { setActive }} = React.useContext(NewsContext);
    const { languageIsPt: pt } = React.useContext(AuthContext);
    if (!article) return null;
    return (
        <div style={{width: 300, height:340, backgroundColor:'rgba(240, 240, 240)', display:'flex', flexDirection:'column', borderRadius: 3}}>
            <ArticleImages imageData={article.imageData} />
            <div style={{alignSelf:'stretch', padding: 10, display:'flex', flexDirection:'column', alignItems:'flex-start', flex: 1}}>
                <span style={{fontSize: '1.2em'}}>{article.title.toUpperCase()}</span>
                <p style={{textAlign:'left', fontSize: '1.1em'}}>{article.body}</p>
            </div>

            <div style={{alignSelf:'stretch', display:'flex', flexDirection:'row', justifyContent:'flex-end', paddingRight: 10, marginBottom: 20}}>
                <Link to="/news/article">
                    <div onClick={() => setActive(article)} className="cas-button">{pt ? 'Mais' : 'More'} {'>'}</div>
                </Link>
            </div>
        </div>
    )
}

export const Article = props => {
    const { article } = props;
    const { isAdmin, languageIsPt: pt } = React.useContext(AuthContext)
    const { actions : { setActive, deleteArticle }} = React.useContext(NewsContext)
    if (!article) return null;

    if (props.editing) {
        return <CreateNews article={article} addNews={props.addNews} cancel={() => props.cancelEdit()} />
    }

    return (
        <div className="news-article">
            {
                isAdmin && <div onClick={() => props.edit()} style={{position:'absolute', top: 10, right: 10, fontSize: 12, cursor:"pointer"}}>Edit {article.id}</div>
            }
            {
                isAdmin && <div onClick={() => deleteArticle(article.id)} style={{position:'absolute', top: 10, right: 70, fontSize: 12, cursor:"pointer"}}>Delete {article.id}</div>
            }
            <div>
                {
                    article.imageData !== null && <img src={article.imageData[0].url} style={{width: 300, height: 200, borderRadius: 3, objectFit: 'cover'}} />
                }
            </div>
            <div style={{paddingLeft: 20}}>
                <div style={{alignSelf:'stretch', display:'flex', flexDirection:'row', justifyContent:'flex-start'}}>
                    <h2 style={{margin: 0}}>{pt ? article.ptTitle : article.title}</h2>
                </div>
                <p>{pt ? article.ptBody : article.body}</p>
            </div>
            <div style={{position:'absolute', bottom: 10, right: 10}}>
                <Link to="/news/article">
                    <div onClick={() => setActive(article)} className="cas-button">{pt ? 'Mais' : 'More'} {'>'}</div>
                </Link>
            </div>
        </div>
    )
}



export const ArticleOld = props => {
    const { isAdmin, languageIsPt: pt } = React.useContext(AuthContext)
    const { article } = props;
    if (!article) return null;


    if (props.editing) {
        return <CreateNews article={article} addNews={props.addNews} cancel={() => props.cancelEdit()} />
    }


    return (
        <div style={{alignSelf:'stretch', display:'flex', flexDirection:'column', margin: 10, padding: 10, border:'solid 1px lightgrey', borderRadius: 3, position:'relative'}}>
            <span style={{position:"absolute", top: 10, right: 10, fontSize: 12}}>Posted on {moment(article.create).format("DD/MM/YYYY - HH:mm")}</span>
            {
                isAdmin && <div onClick={() => props.edit()} style={{position:'absolute', top: 10, left: 10, fontSize: 12, cursor:"pointer"}}>Edit {article.id}</div>
            }
            <h3>{pt ? article.ptTitle : article.title}</h3>
            <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', height: 120, flexWrap:'wrap'}}>
                {
                    article.imageData !== null && article.imageData.map((x, i) => <img src={x.url} style={{maxHeight: 120, maxWidth: 350}} />)
                }
            </div>
            <p>{pt ? article.ptBody : article.body}</p>
        </div>
    )
}

const CreateNews = (props) => {
    let { article } = props;
    if (!article) article = {title: '', content: '', id: 0, visibleFrom: null, visibleTo: null, images: []};
    const [title, setTitle] = React.useState(article.title);
    const [ptTitle, setPtTitle] = React.useState(article.ptTitle);

    const [content, setContent] = React.useState(article.body);
    const [ptContent, setPtContent] = React.useState(article.ptBody);

    const [id, setId] = React.useState(article.id)
    const [visFrom, setVisFrom] = React.useState(article.visibleFrom === null ? null : moment(article.visibleFrom).toDate());
    const [visTo, setVisTo] = React.useState(article.visibleTo === null ? null : moment(article.visibleTo).toDate());
    const [images, setImages] = React.useState(article.imageData || []);

    const addImage = async (e) => {
        const fd = new FormData();
        if (!e.target.files || e.target.files.length === 0) {
            return   
        }
        const file = e.target.files[0];
        fd.append("image", file);
        const data = await axios.post(Api.apiUrl+"/pushnewsimage", fd, {withCredentials: true, 'X-Behalf': null, headers:{'Content-Type': 'multipart/form-data'}}); 
        if (data.data.url) {
            setImages(images.concat([data.data]));
        }
    }

    const publish = () => {
        props.addNews({
            id,
            title,
            body: content,
            visibleFrom: visFrom,
            visibleTo: visTo,
            images: images.map((x => x.key)),
            ptBody: ptContent,
            ptTitle,
        })
    }

    return (
        <div style={{alignSelf:'stretch', border: 'solid 1px lightgrey', borderRadius: 3, padding: 10, margin:10, display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
            <div style={{display:'flex', flexDirection:'row'}}>
                <input placeholder="Title" value={title} style={{width: 200, marginRight: 10}} onChange={e => setTitle(e.target.value)} />
                <input placeholder="PT Title" value={ptTitle} style={{width: 200}} onChange={e => setPtTitle(e.target.value)} />
            </div>
            <div style={{display:'flex', flexDirection:'row', alignSelf:'stretch', marginTop:10, flexWrap:'wrap'}}>
                {
                    images.map((img, i) => 
                    <div onDoubleClick={() => setImages(images.filter(x => x.key !== img.key))} style={{height: 100, cursor:'pointer'}}>
                        <img key={i} src={img.url} style={{maxHeight: 100, margin: 10, maxWidth: 350}} />
                    </div>)

                }
            <input onChange={addImage} accept="image/jpeg" name={`article_add_image`} id={`article_add_image`} type="file" style={{display:'none'}} />
                <label for="article_add_image">
                    <div style={{margin: 10, width: 100, height: 100, cursor:'pointer', backgroundColor:'lightgrey', display:'flex', alignItems:'center', justifyContent:'center'}}>
                        Add Image
                    </div>
                </label>
            </div>
            <div style={{display:'flex', flexDirection:'row', marginTop: 10, alignSelf:'stretch'}}>
                <span>Visible From</span>
                <DatePicker style={{width: 100}} selected={visFrom} onChange={(date) => setVisFrom(date)} />

                <span>Visible To</span>
                <DatePicker style={{width: 100}} selected={visTo} onChange={(date) => setVisTo(date)} />
            </div>
            <textarea 
                value={content}
                onChange={e => setContent(e.target.value)} 
                rows={5} 
                style={{backgroundColor:'lightgrey', color:'black', resize:'none', border:'none', marginTop: 10, alignSelf:'stretch', padding: 10, whiteSpace: 'pre-wrap'}} 
                placeholder="Enter Content Here" />
            
            <textarea 
                value={ptContent}
                onChange={e => setPtContent(e.target.value)} 
                rows={5} 
                style={{backgroundColor:'lightgrey', color:'black', resize:'none', border:'none', marginTop: 10, alignSelf:'stretch', padding: 10, whiteSpace: 'pre-wrap'}} 
                placeholder="Enter Portuguese Content Here" />

            <div style={{display:'flex', flexDirection:'row', alignSelf:'stretch', justifyContent:'flex-end', marginTop: 10}}>
                <div onClick={() => props.cancel()} className="button cancel" style={{marginRight: 20}}>Cancel</div>
                <div onClick={() => publish()} className="button">Save</div>
            </div>
        </div>
    )
}

const NewsScreen = props => {
    const { ids, articles, actions: { loadNews, pushArticle } } = React.useContext(NewsContext)
    const { isAdmin, languageIsPt: pt } = React.useContext(AuthContext);
    const [addingArticle, setAddingArticle] = React.useState(false);
    const [editing, setEditing] = React.useState(null);

    const addNews = (article) => {
        pushArticle(article);
        setAddingArticle(false);
        setEditing(null);
    }

    return (
        <Screen title={pt ? 'Novidades' : "News"}>
            <div style={{display:'flex', flexDirection:'column', alignSelf:'stretch', flex: 1, padding: 20}}>
                {
                    isAdmin && !addingArticle && <div onClick={() => setAddingArticle(true)} className="button">Add Article</div>
                }
                {
                    addingArticle && <CreateNews addNews={addNews} cancel={() => setAddingArticle(false)} />
                }
                {
                    ids.map((id, i) => <Article addNews={addNews} cancelEdit={() => setEditing(null)} edit={() => setEditing(id)} editing={id === editing} article={articles[id]} />)
                }
            </div>
        </Screen>
    )
}

export default NewsScreen