import React from 'react';
import LoginModal from './loginModal';
import * as Api from '../../api';
import AccountModal from './accountModal';
import ApplyModal from './applyForAccountModal';
import ForgotModal from './forgotPasswodModal';

export const AuthContext = React.createContext();

const getTokenFromCookies = () => {
    let cookies = document.cookie.split(';')
    for (var i=0; i < cookies.length; i++) {
        if (cookies[i].startsWith('token=')) {
            const token = cookies[i].replaceAll("token=", '');
            return token;
        }
    }
    return null;
}

class AuthLayer extends React.Component {
    state = {
        isLoggedIn: false,
        token: null,
        tokenExpires: null,
        loginModalIsOpen: false,
        isAdmin: false,
        accountModalIsOpen: false,
        applyModalShowing: false,
        forgotModalShowing: false,
        loginMessage: '',
        account: {},
        languageIsPt: true,
        mobileMenuOpen: false
    }

    setMobileMenuOpen = (open) => {
        this.setState({mobileMenuOpen: open})
    }

    componentDidUpdate(prevprops, prevstate) {
        if (prevstate.isLoggedIn === false && this.state.isLoggedIn === true) {
            this.getAccount();
        }
    }
    
    getAccount = async () => {
        const {account, portuguese} = await Api.Get("/user/account", false);
        this.setState({account, languageIsPt: portuguese});
    }

    setForgotModalShowing = show => {
        this.setState({forgotModalShowing: show})
    }

    setApplyModalShowing = show => {
        this.setState({applyModalShowing: show})
    }

    setShowAccountModal = (show) => {
        this.setState({accountModalIsOpen: show})
    }

    loginWithToken = async () => {
        const token = getTokenFromCookies();
        try {
            const data = await Api.Get("/checktoken", true);
            if (data.status === 200) {
                const { isAdmin } = data.data;
                this.setState({token: token, isLoggedIn: true, isAdmin});
            }
        } catch(e) {

        }
    }

    componentDidMount() {
        this.loginWithToken();
    }

    login = async (username, password, error=() => {}) => {
        try {
            const data = await Api.Post("/login", {username, password }, true);
            error('')
            if (data.status === 200) {
                if (data.data.Token) {
                    this.setState({tokenExpires: data.data.Expires, token: data.data.Token, isLoggedIn: true, loginModalIsOpen: false, isAdmin: data.data.IsAdmin}, () => {
                    })
                }
            }
        } catch(e) {
            error("Couldn't log in - please try again")
        }
    }

    logout = async () => {
        await Api.Get("/logout", true, null);
        this.setState({isLoggedIn: false, isAdmin: false, token: null, tokenExpires: null, loginModalIsOpen: false, accountModalIsOpen: false})
    }

    setLoginModalOpen = (open, message='') => this.setState({loginModalIsOpen: open, loginMessage: message});

    setLanguageIsPt = pt => this.setState({languageIsPt: pt}, () => {
        Api.Post("/user/language", { prt: pt }, false, null);
    });

    render() {
        return (
            <AuthContext.Provider value={
                {
                    ...this.state, 
                    actions: {
                        setLoginModalOpen: this.setLoginModalOpen,
                        login: this.login,
                        logout: this.logout,
                        setShowAccountModal: this.setShowAccountModal,
                        setApplyModalShowing: this.setApplyModalShowing,
                        setForgotModalShowing: this.setForgotModalShowing,
                        setLanguageIsPt: this.setLanguageIsPt,
                        setMobileMenuOpen: this.setMobileMenuOpen
                    }
                }
                }>
                {this.props.children}
                <LoginModal />
                <AccountModal />
                <ApplyModal />
                <ForgotModal />
            </AuthContext.Provider>
        )
    }
}

export default AuthLayer