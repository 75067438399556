import React from 'react';
import * as Api from '../api';
import { AuthContext } from './auth';

export const NewsContext = React.createContext();

const NewsWrapper = props => {
    const authContext = React.useContext(AuthContext);
    return (
        <NewLayer authContext={authContext}>
            {props.children}
        </NewLayer>
    )
}

class NewLayer extends React.Component {
    state = {
        ids: [],
        articles: {},
        activeArticle: null
    }

    setActive = a => {
        this.setState({activeArticle: a})
    }
    
    componentDidMount() {
        this.loadNews();
    }

    componentDidUpdate(prevprops) {
        if (prevprops.authContext.isLoggedIn !== this.props.authContext.isLoggedIn) {
            this.setState({
                ids: [],
                articles: {}
            }, () => {
                this.loadNews()
            })
        }
    }

    pushArticle = async article => {
        const data = await Api.Post("/pushnews", article);
        this.setState({
            ...this.state,
            ids: !(data.id in this.state.articles) ? [data.id].concat(this.state.ids) : this.state.ids,
            articles: {
                ...this.state.articles,
                [data.id]: data
            }
        })
    }

    loadNews = async () => {
        const offset = this.state.ids.length;
        const limit = 20;
        const data = await Api.Get(`/news?offset=${offset}&limit=${limit}`);
        const { ids, articles } = data;
        this.setState({
            ...this.state,
            ids: this.state.ids.concat(ids),
            articles: {
                ...this.state.articles,
                ...articles
            }
        });
    }

    deleteArticle = async (id) => {
        const data = await Api.Post("/news/delete", {id});
        this.setState({
            ids: this.state.ids.filter(x => x !== id)
        });
    }


    render() {
        return (
            <NewsContext.Provider value={{...this.state, actions: { deleteArticle: this.deleteArticle, loadNews: this.loadNews, pushArticle: this.pushArticle , setActive: this.setActive}}}>
                {this.props.children}
            </NewsContext.Provider>
        )
    }
}

export default NewsWrapper