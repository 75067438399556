import React, { useContext } from 'react';
import Modal from 'react-modal';
import { AuthContext } from './index';
import { modalStyle } from '../modalStyle';

const ModalContent = props => {
    const { languageIsPt: pt, loginMessage,  actions: { setLoginModalOpen, setApplyModalShowing, login, setForgotModalShowing } } = useContext(AuthContext);
    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [error, setError] = React.useState("");

    return (
        <div id="loginModal">
            <h2>{pt ? 'A minha Conta ' : 'Sign In to Your Account'}</h2>
            {
                loginMessage !== '' && <p>{loginMessage}</p>
            }
            <input className="email" type="text" value={username} placeholder="Username" style={{marginBottom: 10}} onChange={e => setUsername(e.target.value)} />
            <input className="password" type="password" value={password} placeholder="Password" onChange={e => setPassword(e.target.value)} />

            <div style={{padding: 10, alignSelf:'stretch', justifyContent:'flex-end', flexDirection:'row', display:'flex', color:"red"}}>
                {error}
            </div>
            <div className="between">
                <div onClick={() => setLoginModalOpen(false)} className="button cancel">
                    {pt ? 'Cancelar ' : 'Cancel'}
                </div>
                <div onClick={() => login(username, password, error => setError(error))} className="button large">
                    Login
                </div>
            </div>
            <div className="loginSecondaryActions">
                <p style={{cursor:'pointer'}} onClick={() => {
                    setLoginModalOpen(false);
                    setApplyModalShowing(true);
                }}>{pt ? 'Novo registo de conta' : 'Apply for an Account'}</p>
                <p
                    onClick={() => {
                        setLoginModalOpen(false);
                        setForgotModalShowing(true);
                    }} 
                style={{cursor:"pointer"}}>{pt ? 'Esqueci-me da password ' : 'Forgot your Password'}</p>
            </div>
        </div>
    )
}

const LoginModal = props => {
    const { loginModalIsOpen, actions } = useContext(AuthContext);

    return (
        <Modal
            isOpen={loginModalIsOpen}
            onRequestClose={() => actions.setLoginModalOpen(false)}
            style={modalStyle}
        >
            <ModalContent />
        </Modal>
    )
}

export default LoginModal;