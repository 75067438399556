import React from 'react';
import Screen from './screen';
import { AuthContext } from '../layers/auth';
import logo from '../ebft.png';

const EBFT = props => {
    const { languageIsPt: pt, isAdmin } = React.useContext(AuthContext);

    return (
        <Screen title={"ELAINE BAIN FAMILY TRUST"}>
            {
                pt ?
                <div style={{flexDirection:'column', alignItems:'flex-start', textAlign: 'left'}}>
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', fontSize: '1.3rem'}}>
                        <img src={logo} style={{width: 140*(963/785), height: 140}} />
                    </div>
                    <p>O objetivo do Elaine Bain Family Trust (EBFT) é oferecer atenção e oportunidade aos jovens que beneficiarão de uma experiência educacional alternativa para complementar a sua educação escolar padrão. </p>
                    <p>Originalmente estabelecida na Inglaterra, e inicialmente conhecida como Albany Children’s Trust (ACT), a instituição de caridade recebia crianças do primeiro ano do ensino médio uma vez por semana durante o período letivo para atividades que duravam duas ou quatro horas, dependendo do local das atividades. </p>
                    <p>As atividades organizadas pela ACT permitiram aos jovens descobrir novos interesses e competências, bem como desenvolver confiança e competências sociais. Isso, por sua vez, ajudou a orientar a escola, os alunos e suas famílias quanto à sua direção pós-escola, ou seja, mais educação ou emprego. </p>
                    <p>A instituição de caridade obteve algum sucesso até ao começo da pandemia, quando a atividade normal do ACT foi forçada a cessar. </p>
                    <p>Quando a pandemia despontou a nível mundial, a nossa querida amiga e curadora fundadora, Elaine Bain, faleceu. Em sua memória, o ACT tornou-se EBFT, e como a instituição de caridade do Reino Unido agora pode retomar as atividades, a EBFT Portugal foi criada para oferecer as mesmas oportunidades aos jovens locais. </p>
                </div>
            :
                <div style={{flexDirection:'column', alignItems:'flex-start', textAlign: 'left'}}>
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                        <img src={logo} style={{width: 140*(963/785), height: 140}} />
                    </div>
                    <p>The objective of the Elaine Bain Family Trust (EBFT) is to provide attention and opportunity to youngsters who will benefit from an alternative educational experience to supplement their standard school education.</p>
                    <p>Originally established in England, and at the time known as Albany Children’s Trust (ACT), the charity took first year secondary school children once a week during term time for activities lasting two or four hours depending on the location of the activities.</p>
                    <p>The activities organised by ACT allowed the youngsters to discover new interests and abilities, as well as develop confidence and social skills. This in turn helped guide the school, students and their families as to their direction post-school, i.e. further education or employment.</p>
                    <p>The charity achieved some success until the advent of the pandemic when normal ACT activity was forced to cease.</p>
                    <p>At the very start of the pandemic our dear friend and founding trustee, Elaine Bain, passed away. In her memory, ACT became EBFT, and as the UK charity are now able to resume activities, so EBFT Portugal has been established to offer the same opportunities to youngsters locally. </p>
                </div>
            }
        </Screen>
    )
}

export default EBFT