import React from 'react';
import * as Api from '../../api';
import { AuthContext } from '../auth';
import { ProductsContext } from '../products/index';
import UploadModal from './uploadModal.jsx';

export const ManageContext = React.createContext();

const ManageWrapper = props => {
    const authContext = React.useContext(AuthContext)
    const productsContext = React.useContext(ProductsContext)
    return (
        <ManageLayer authContext={authContext} productsContext={productsContext}>
            {props.children}
        </ManageLayer>
    )
}

const defaultProductQuery = {
    offset: 0,
    limit: 100,
    orderBy: 'name',
    reverse: false,
    searchTerm: null,
    favesOnly: false,
    promotionsOnly: false,
    promotionIds: [],
    brands: [],
    categories: {},
    upfront: false,
    includeAmbient: true,
    includeFrozen: true,
    includeSeasonal: true,
    includeCatering: true
}

class ManageLayer extends React.Component {
    state = {
        currentQuery: defaultProductQuery,
        updatedProducts: {},
        currentQueryIds: [],
        currentQueryCount: 0,
        productsPerPage: 100,
        reloadTick: 0,
        uploadModalIsOpen: false
    }

    search = (term) => {
        this.makeQuery({searchTerm: term})
    }

    makeQuery = (updates) => {
        this.setState({currentQuery: {...this.state.currentQuery, ...updates}, reloadTick: this.state.reloadTick + 1})
    }

    setPage = (page) => {
        const offset = this.state.currentQuery.limit * page;
        this.makeQuery({offset});
    }

    setUploadModalOpen = show => this.setState({uploadModalIsOpen: show})

    componentDidMount() {
        if (this.props.authContext.isAdmin) {
            this.executeCurrentQuery();
        }
    }

    componentDidUpdate(prevprops, prevstate) {
        if (this.state.reloadTick > prevstate.reloadTick) {
            this.executeCurrentQuery();
        }
        if (this.props.authContext.isAdmin && !prevprops.authContext.isAdmin) {
            this.executeCurrentQuery();
        }
    }

    executeCurrentQuery = async () => {
        const data = await Api.Post("/products", this.state.currentQuery, false, null);
        const { count, ids } = data;
        this.props.productsContext.actions.getProductData(ids || []);
        this.setState({currentQueryCount: count, currentQueryIds: ids || []});
    }

    setCurrentQuery = parts => {
        this.setState({currentQuery: {...this.state.currentQuery, ...parts}, reloadTick: this.state.reloadTick + 1});
    }

    setOfferQty = async (offerId, qty) => {
        if (isNaN(parseInt(qty))) {
            return;
        }
        const prod = await Api.Post("/set_qty", {offerId, qty}, false, null);
        this.props.productsContext.actions.insertProducts({[prod.id]: prod});
    }
    
    setOfferPrice = async(offerId, price) => {
        if (isNaN(parseFloat(price))) {
            return;
        }
        const prod = await Api.Post("/set_price", {offerId, price}, false, null);
        this.props.productsContext.actions.insertProducts({[prod.id]: prod});
    }

    render() {
        return (
            <ManageContext.Provider value={{
                ...this.state,
                actions: {
                    setUploadModalOpen: this.setUploadModalOpen,
                    execute: this.executeCurrentQuery,
                    setPage: this.setPage,
                    search: this.search,
                    setQuery: this.setCurrentQuery,
                    setOfferQty: this.setOfferQty,
                    setOfferPrice: this.setOfferPrice
                }
            }}>
                {
                    this.props.children
                }
                <UploadModal />
            </ManageContext.Provider>
        )
    }
}

export default ManageWrapper;