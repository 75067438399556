import React from 'react';
import { w3cwebsocket as ws } from 'websocket';
import * as Api from '../../api';
import { ProductsContext } from '../products';

const RealtimeWrapper = props => {
    const productsContext = React.useContext(ProductsContext)
    return (
        <RealTime productsContext={productsContext}>
            {props.children}
        </RealTime>
    )
}

class RealTime extends React.Component {
    _client = null;
    _wsInterval = null;
    _monitor = null
    _hasFailed = false

    componentDidMount() {
        //this.setupWebSocket();
    }

    componentWillUnmount() {
        //this.handleClose()
    }

    resetIfNecessary = () => {
        if (this._client) {
            if (this._client.readyState !== 1) {
                this.handleClose();
            }
        }
    }

    handleClose = () => {
        if (this._client !== null) {
            this._client.close();
            this._client = null;
        }
        if (this._hasFailed === false) {
            this._hasFailed = true;
        }
        if (this._wsInterval === null) {
            clearInterval(this._monitor);
            this._monitor = null;
            this._wsInterval = setInterval(this.setupWebSocket, 1000);
        }
    }

    setupWebSocket = async () => {
        if (this._client) {
            if (this._client.readyState === 1) {
                return
            } else {
                this.handleClose()
            }
        }

        this._client = new ws(Api.wsUrl);

        this._client.onopen = () => {
            if (this._wsInterval !== null) {
                clearInterval(this._wsInterval)
                this._wsInterval = null;
            }
            this._monitor = setInterval(() => this.resetIfNecessary(), 3000);
            this._hasFailed = false;
            this._client.onClose = () => {}

        }

        this._client.onmessage = message => {
            const parsedMessage = JSON.parse(message.data)
            let payload = null;
            if (parsedMessage.json) {
                payload = JSON.parse(parsedMessage.json);
            }

            if (parsedMessage.type === "offerStockUpdate") {
                this.props.productsContext.actions.applyStockUpdate(payload);
            } else if (parsedMessage.type === 'pleaseRefreshProducts') {
                this.props.productsContext.actions.refreshProductData(payload, false);
            }
        }

        this._client.onerror = () => {
            this.handleClose();
        }
    }

    render() {
        return (
            <div>
                {this.props.children}
            </div>
        )
    }
}

export default RealtimeWrapper;