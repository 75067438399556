import React from 'react';
import Modal from 'react-modal';
import { AuthContext } from '../auth';
import { modalStyle } from '../modalStyle';

const ModalContent = props => {
    const { offer, basketQty } = props;
    const { languageIsPt: pt } = React.useContext(AuthContext);
    if (!offer) return null;
    return (
        <div id="loginModal">
            <h2>{pt ? 'Lamentamos, não dispomos de stock suficiente para ser adicionado a esta encomenda' : 'Sorry, we don\'t have enough stock to add this to your order'}</h2>

            <p>{pt ? `Temos ${offer.quantity}, estão ${basketQty} no seu cesto` : `We have ${offer.quantity}, there are ${basketQty} in your basket`}</p>

            <div className="right">
                <div onClick={() => props.close()} className="button">OK</div>
            </div>
        </div>
    )
}

const NoMoreModal = props => {
    const { offer, basketQty, close } = props;

    return (
        <Modal
            isOpen={offer !== null}
            onRequestClose={() => close()}
            style={modalStyle}
        >
            <ModalContent close={close} offer={offer} basketQty={basketQty} />
        </Modal>
    )
}

export default NoMoreModal;