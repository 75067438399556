import axios from 'axios';

const apiIp = "www.albanyportugal.com/api";
const wsIp = "www.albanyportugal.com/ws";

export const apiUrl = `http${apiIp !== 'localhost:9797/api' ? 's' : ''}://${apiIp}`;
export const wsUrl = `ws${wsIp !== 'localhost:9798' ? 's' : ''}://${wsIp}`

export const Form = async(endpoint, data) => {
    const response = await axios.post(apiUrl + endpoint, data, { withCredentials: true });
    return response.data;
}

export const Post = async(endPoint, data, fullResponse = false, onBehalfOf = null, headers = {}) => {
    const jdata = JSON.stringify(data);
    const response = await axios.post(apiUrl + endPoint, jdata, { withCredentials: true, headers: { 'X-behalf': onBehalfOf, ...headers } });
    if (fullResponse === true) {
        return response;
    }
    return response.data;
}

export const Get = async(endPoint, fullResponse = false, onBehalfOf = null, headers = {}) => {
    const response = await axios.get(apiUrl + endPoint, { withCredentials: true, headers: { 'X-behalf': onBehalfOf, ...headers } });
    if (fullResponse === true) {
        return response;
    }
    return response.data
}