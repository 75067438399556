import React from 'react';
import * as Api from '../../api';
import { AuthContext } from '../auth';
import { RepContext } from '../rep';
import EditModal from './editProductModal';
import { removeKeyFromObj } from '../../utils';

export const ProductsContext = React.createContext();

const defaultProductQuery = {
    offset: 0,
    limit: 40,
    orderBy: 'name',
    reverse: false,
    searchTerm: null,
    favesOnly: false,
    promotionsOnly: false,
    stockOnly: false,
    promotionIds: [],
    brands: [],
    categories: {},
    upfront: true,
    includeFrozen : false,
    includeAmbient: true,
    includeSeasonal: true,
    includeCatering: false
}

const ProductContextWrapper = props => {
    const authContext = React.useContext(AuthContext);
    const repContext = React.useContext(RepContext);
    return (
        <ProductsLayer repContext={repContext} authContext={authContext}>
            {props.children}
        </ProductsLayer>
    )
}

class ProductsLayer extends React.Component {
    state = {
        listView: false,
        currentQuery: defaultProductQuery,
        reloadProductsTick: 0,
        currentQueryIDs: [],
        currentQueryCount: 0,
        productData: {},
        productsPerPage: defaultProductQuery.limit,
        categories: {},
        brands: [],
        faves: [],
        editingProduct: null,
        counts: null,
        section: "ambient",
        topPicks: {},
        brandSugg: [],
        dataTick: 0,
        tick: 0,
        categoryTranslations: {}
    }

    getCategoryTranslations = async () => {
        const translations = await Api.Get('/translations')
        this.setState({categoryTranslations: translations})
    }

    setListView = listView => this.setState({listView})

    getBrandSugg = async () => {
        const brandSugg = await Api.Get("/brands");
        this.setState({brandSugg})
    }

    getTopPicks = async () => {
        const topPicks = await Api.Get("/products/top-picks");
        this.setState({topPicks, productData: {...this.state.productData, ...topPicks}});
    }

    goToSection = section => {
        if (section === 'ambient') {
            this.setState({section: "ambient"}, () => {
                this.makeProductsQuery({includeFrozen: false, includeSeasonal: false, includeAmbient: true, includeCatering: false})
            })
        } else if (section === 'frozen') {
            this.setState({section:'frozen'}, () => {
                this.makeProductsQuery({includeFrozen: true, includeSeasonal: false, includeAmbient: false, includeCatering: false})
            })
        } else if (section === 'seasonal') {
            this.setState({section:"seasonal"}, () => {
                this.makeProductsQuery({includeFrozen: false, includeSeasonal: true, includeAmbient: false, includeCatering: false})
            })
        } else if (section === 'catering') {
            this.setState({section: "catering"}, () => {
                this.makeProductsQuery({includeFrozen: false, includeSeasonal: false, includeAmbient: false, includeCatering: true})
            })
        }
    }

    getCounts = async () => {
        const { ambient, frozen, seasonal } = await Api.Get("/products/counts");
        this.setState({counts: { ambient, frozen, seasonal }})
    }

    updateProducts = async (products, callback) => {
        const {failed, succeeded} = await Api.Post("/products/update", products);
        this.setState({productData: {...this.state.productData, ...succeeded}});
        callback(failed, succeeded);
    }

    updateTopPicks = (product) => {
        if (product.id in this.state.topPicks) {
            const newTopPicks = {};
            Object.keys(this.state.topPicks).forEach((key, i) => {
                if (parseInt(key) !== product.id) {
                    newTopPicks[key] = this.state.topPicks[key];
                }
            })
            this.setState({topPicks: newTopPicks, tick: this.state.tick + 1});
        } else {
            this.setState({topPicks: {...this.state.topPicks, [product.id]: product}, tick: this.state.tick + 1});
        }
    }

    editProduct = async (product, error) => {
        const response = await Api.Post("/product/update", product);
        const topPickChanged = this.state.productData[product.id].isTopPick !== product.isTopPick;
        if (response.id) {
            this.setState({productData: {...this.state.productData, [response.id]: response}, editingProduct: null}, () => {
                if (topPickChanged) {
                    this.updateTopPicks(response);
                }
            });
        } else {
            error(response.join(', '));
        }
    }

    setEditingProduct = product => {
        this.setState({editingProduct: product})
    }

    clearFilters = () => this.setState({currentQuery: defaultProductQuery, reloadProductsTick: this.state.reloadProductsTick + 1});
    filtersExists = () => {
        const q = this.state.currentQuery;
        return q.offset > 0 || q.searchTerm !== null || q.favesOnly || q.promotionIds.length > 0 || q.promotionsOnly || q.brands.length > 0 || Object.keys(q.categories).length > 0;
    }

    getFaves = async () => {
        if (this.props.authContext.isLoggedIn) {
            const faves = await Api.Get("/faves", false, this.props.repContext.behalfOf);
            this.setState({faves});
        }
    }

    setFave = async (productId, isFave) => {
        if (this.state.faves.indexOf(productId) === -1) {
            if (isFave) {
                this.setState({faves: this.state.faves.concat([productId])})
            }
        } else if (!isFave) {
            this.setState({faves: this.state.faves.filter(x => x !== productId)});
        }
        Api.Post("/faves/set", {productId, isFave}, false, this.props.repContext.behalfOf);
    }

    getBrandsAndCats = async () => {
        const brands = await Api.Get('/products/brands');
        const categories = await Api.Get("/products/categories", false, this.props.repContext.behalfOf);
        this.setState({categories, brands})
    }

    componentDidMount() {
        this.getBrandSugg()
        this.executeCurrentQuery();
        this.getTopPicks()
        this.getBrandsAndCats();
        this.getCounts();
        this.getCategoryTranslations()
    }

    componentDidUpdate(prevprops, prevstate) {
        if (this.state.reloadProductsTick > prevstate.reloadProductsTick) {
            this.executeCurrentQuery();
        }
        if (prevprops.authContext.isLoggedIn === false && this.props.authContext.isLoggedIn === true) {
            this.executeCurrentQuery();
            this.getBrandsAndCats();
            this.getFaves();
        }
        if ((prevprops.repContext.behalfOf !== this.props.repContext.behalfOf) || (prevprops.authContext.isLoggedIn === true && this.props.authContext.isLoggedIn === false)) {
            this.setState({currentQuery: defaultProductQuery, productData: {}}, () => {
                this.executeCurrentQuery();
                this.getFaves();
                this.getBrandsAndCats()
            })
        }
        if (prevprops.repContext.shouldRefreshTick < this.props.repContext.shouldRefreshTick) {
            this.executeCurrentQuery();
        }
    }

    executeCurrentQuery = async () => {
        const data = await Api.Post("/products", this.state.currentQuery, false, this.props.repContext.behalfOf);
        const { count, ids, products } = data;

        const needIds = (ids || []).filter(x => !(x in this.state.productData))
        if (needIds.length > 0) {
            const productData = await Api.Post("/products/data", needIds, false, this.props.repContext.behalfOf);
            this.setState({productData: {...this.state.productData, ...productData}}, () => {
                this.setState({
                    currentQuery: {
                        ...this.state.currentQuery, 
                        offset: this.state.currentQuery.offset > count ? 0 : this.state.currentQuery.offset // IF OFFSET IS BEYOND NEW COUNT, PUT TO 0
                    },
                    currentQueryCount: count,
                    currentQueryIDs: ids || [], 
                    productData: {
                        ...this.state.productData, ...products
                    }
                })
            })
        } else {
            this.setState({
                currentQuery: {
                    ...this.state.currentQuery, 
                    offset: this.state.currentQuery.offset > count ? 0 : this.state.currentQuery.offset // IF OFFSET IS BEYOND NEW COUNT, PUT TO 0
                },
                currentQueryCount: count,
                currentQueryIDs: ids || [], 
                productData: {
                    ...this.state.productData, ...products
                }
            })
        }
    }

    clearCache = () => this.setState({productData: {}}, () => this.executeCurrentQuery())

    refreshProductData = async(ids) => {
        ids = (ids || []).filter(x => x in this.state.productData)
        const data = await Api.Post("/products/data", ids, false, this.props.repContext.behalfOf);
        this.setState({productData: {...this.state.productData, ...data}});
    }

    getProductData = async (ids, ignoreCached=true) => {
        ids = ids || [];
        if (ignoreCached) {
            ids = ids.filter(x => !(x in this.state.productData));
        }
        if (ids.length > 0) {
            const data = await Api.Post("/products/data", ids, false, this.props.repContext.behalfOf);
            this.setState({productData: {...this.state.productData, ...data}});
        }
    }

    makeProductsQuery = (query) => {
        if ('categoryOnes' in query || 'categoryTwos' in query || 'searchTerm' in query || 'brands' in query || 'favesOnly' in query || 'promotionsOnly' in query || 'promotionIds' in query) {
            query.offset = 0;
        }
        if ('searchTerm' in query) {
            query.upfront = false;
        }

        this.setState({
            currentQuery: {...this.state.currentQuery, ...query}, 
            reloadProductsTick: this.state.reloadProductsTick + 1
        });
    }


    nextPage = () => {
        this.makeProductsQuery({offset: this.state.currentQuery.offset + this.state.productsPerPage})
    }

    setPage = (page) => {
        const offset = this.state.currentQuery.limit * page;
        this.makeProductsQuery({offset});
    }

    setCatOne = c => {
        const categories = this.state.currentQuery.categories;
        if (!(c in categories) || (c in categories && categories[c].length > 0)) {
            this.makeProductsQuery({categories: {...categories, [c]: []}});
        } else {
            this.makeProductsQuery({categories: removeKeyFromObj(categories, c)});
        }
    }

    setCatTwo = (catOne, catTwo) => {
        const categories = this.state.currentQuery.categories;
        if (!(catOne in categories)) {
            this.makeProductsQuery({categories: {...categories, [catOne]: [catTwo]}});
        } else {
            const current = categories[catOne];
            if (current.length === 0) {
                this.makeProductsQuery({categories: {...categories, [catOne]: this.state.categories[catOne].filter(x => x !== catTwo)}});
            } else {
                if (current.indexOf(catTwo) === -1) {
                    this.makeProductsQuery({categories: {...categories, [catOne]: current.concat([catTwo])}});
                } else {
                    const afterRemoves = current.filter(x => x !== catTwo);
                    if (afterRemoves.length === 0) {
                        this.makeProductsQuery({categories: removeKeyFromObj(categories, catOne)});
                    } else {
                        this.makeProductsQuery({categories: {...categories, [catOne]: afterRemoves}})
                    }
                }
            }
        }
    }


    applyStockUpdate = (updates) => {
        let { productData } = this.state;
        for (var i=0; i < updates.length; i++) {
            const { productID, offerId, quantity } = updates[i]
            if (productID in productData) {
                let product = productData[productID];
                if (product.offers !== null) {
                    const offers = product.offers;
                    const matchingOffer = offers.find(x => x.id === offerId);
                    if (matchingOffer !== undefined) {
                        const updatedOffer = {...matchingOffer, quantity};
                        product = {
                            ...product,
                            offers: offers.filter(x => x.id !== offerId).concat([updatedOffer])
                        }
                    }
                }
                if (product.ox !== null) {
                    const ox = product.ox;
                    const matchingOx = ox.find(x => x.id === offerId);
                    if (matchingOx !== undefined) {
                        const updatedOx = {...matchingOx, quantity};
                        product = {
                            ...product,
                            ox: ox.filter(x => x.id !== offerId).concat([updatedOx])
                        }
                    }
                }
                productData = {...productData, [product.id]: product}
            }
        }
        this.setState({productData})
    }

    insertProducts = (prods) => {
        this.setState({
            productData: {
                ...this.state.productData,
                ...prods
            }
        });
    }

    uploadPic = async (ean, file, id) => {
        const fd = new FormData();
        fd.append("ean", ean);
        fd.append("image", file);
        const resp = await Api.Form("/product_images", fd);
        this.setState({
            productData: {
                ...this.state.productData,
                [id]: {
                    ...this.state.productData[id],
                    image: resp
                }
            }
        });
        return resp;
    }

    render() {
        return (
            <ProductsContext.Provider value={
                {
                    ...this.state, 
                    actions: {
                        nextPage: this.nextPage,
                        setQuery: this.makeProductsQuery,
                        setFave: this.setFave,
                        setPage: this.setPage,
                        filtersExist: this.filtersExists,
                        clearFilters: this.clearFilters,
                        setEditingProduct: this.setEditingProduct,
                        editProduct: this.editProduct,
                        setCatOne: this.setCatOne,
                        setCatTwo: this.setCatTwo,
                        getProductData: this.getProductData,
                        updateProducts: this.updateProducts,
                        applyStockUpdate: this.applyStockUpdate,
                        refreshProductData: this.refreshProductData,
                        goToSection: this.goToSection,
                        setListView: this.setListView,
                        clearCache: this.clearCache,
                        insertProducts: this.insertProducts,
                        uploadPic: this.uploadPic
                    }
                }}>
                {this.props.children}
                <EditModal />
            </ProductsContext.Provider>
        )
    }
}

export default ProductContextWrapper