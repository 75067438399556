import React, { useContext } from 'react';
import Modal from 'react-modal';
import { RepContext } from './index';
import { modalStyle } from '../modalStyle';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import * as Api from '../../api';
import { downloadFile } from '../../utils';

const ModalContent = props => {
    const { matrixModalShowing, actions: {setMatrixModalShowing } } = React.useContext(RepContext);
    const [startDate, setStartDate] = React.useState(new Date);
    const [endDate, setEndDate] = React.useState(new Date);
    const [settingStart, setSettingStart] = React.useState(true);
    const [settingEnd, setSettingEnd] = React.useState(true);

    const submit = async () => {
        const st = moment(startDate).format('YYYY-MM-DD');
        const en = moment(endDate).format('YYYY-MM-DD');
        const data = await Api.Post("/report/matrix", {start: st, end: en}, false, null);
        if (data.url) {
            downloadFile('Matrix.xlsx', data.url);
            setMatrixModalShowing(false);
        } 
    }

    return (
        <div id="matrixModal">
            <h1>Sales Matrix</h1>
            <div style={{display: "flex", flexDirection:"column", flexWrap: 'wrap', height: 500, position:'relative'}}>
                
                <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', alignSelf:'stretch'}}>
                    <span>Start Date</span>
                    <span style={{position:'relative', padding: 10}}>
                        {settingStart &&
                            <div id="matrix-st">
                                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                            </div>
                        }
                    </span>
                </div>
                
                <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', alignSelf:'stretch'}}>
                    <span>End Date</span>
                    <span style={{position:'relative', padding: 10}}>
                        {settingEnd &&
                            <div id="matrix-en">
                                <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
                            </div>
                        }
                    </span>
                </div>
                <div style={{position:'absolute', bottom: 0, right: 0, left: 0, flexDirection:'row', padding: 5, justifyContent:'space-between', alignItems:'center', display:'flex'}}>
                    <div onClick={() => setMatrixModalShowing(false)} className='cancel button'>Cancel</div>
                    <div onClick={() => submit()} className='button'>Download</div>
                </div>
            </div>
        </div>
    )
}


const MatrixModal = props => {
    const { matrixModalShowing, actions: {setMatrixModalShowing } } = React.useContext(RepContext);

    return (
        <Modal
            isOpen={matrixModalShowing}
            onRequestClose={() =>setMatrixModalShowing(false)}
            style={modalStyle}
        >
            <ModalContent />
        </Modal>
    )
}

export default MatrixModal;