import React from 'react';
import { AuthContext } from '../auth';
import * as Api from '../../api';
import CustomerModal from './editCustomer';
import CreateCustomerModal from './createCustomer';
import MatrixModal from './salesMatrix';
export const RepContext = React.createContext();

const RepWrapper = props => {
    const authContext = React.useContext(AuthContext);
    return (
        <RepLayer authContext={authContext}>
            {props.children}
        </RepLayer>
    )
}

class RepLayer extends React.Component {
    state = {
        customers: [],
        behalfOf: null,
        isAdmin: false,
        selectedCustomer: null,
        showCustomerModal: false,
        showCreateCustomerModal: false,
        shouldRefreshTick: 0,
        matrixModalShowing: false
    }

    setMatrixModalShowing = (showing) => {
        this.setState({matrixModalShowing: showing})
    }

    updateCustomer = async (customer, cb=() => {}, success=() => {}) => {
        const response = await Api.Post("/user/push", customer);
        if (response.errors) {
            cb(response.errors.join(', '));
        } else {
            this.setState({
                selectedCustomer: response.user,
                showCustomerModal: false, 
                shouldRefreshTick: this.state.shouldRefreshTick + 1,
                behalfOf: response.user.id,
            }, () => success());
        }
    }

    setCustomerModalShowing = show => this.setState({showCustomerModal: show});
    setCreateCustomerModalShowing = show => this.setState({showCreateCustomerModal: show});

    getCustomerToEdit = async (id) => {
        const q = {id}
        const selectedCustomer = await Api.Post("/user/get", q, false, null);
        this.setState({selectedCustomer});
    }

    getCustomers = async () => {
        const customers = await Api.Get("/customers/list");
        this.setState({customers});
    }

    setBehalfOf = id => {
        this.setState({behalfOf: id}, () => this.getCustomerToEdit(id));
    }

    componentDidMount() {
        if (this.props.authContext.isAdmin) {
            this.setState({isAdmin: true}, () => {
                this.getCustomers();
            })
        }
    }

    componentDidUpdate(prevprops, prevstate) {
        if (this.props.authContext.isAdmin && !prevprops.authContext.isAdmin) {
            this.setState({isAdmin: true}, () => {
                this.getCustomers();
            });
        }
        if (this.props.authContext.isLoggedIn === false && prevprops.authContext.isLoggedIn === true) {
            this.setState({
                customers: [], behalfOf: null, isAdmin: false, selectedCustomer: null, showCustomerModal: false, showCreateCustomerModal: false
            })
        }
    }

    render() {
        return (
            <RepContext.Provider value={{...this.state, actions: {
                    behalf: this.setBehalfOf, 
                    setCustomerModalShowing: this.setCustomerModalShowing, 
                    updateCustomer: this.updateCustomer,
                    setCreateCustomerModalShowing: this.setCreateCustomerModalShowing,
                    setMatrixModalShowing: this.setMatrixModalShowing
                }}}>
                {this.props.children}

                <CustomerModal />
                <CreateCustomerModal />
                <MatrixModal />
            </RepContext.Provider>
        )
    }
}

export default RepWrapper;