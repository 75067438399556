import React, { useState } from 'react';
import { AuthContext } from '../layers/auth';
import { ManageContext } from '../layers/manage';
import { ProductsContext } from '../layers/products';
import { downloadFile } from '../utils';
import * as Api from '../api';
import Paginator from './paginator';


const ManageScreen = props => {
    const [updates, setUpdates] = React.useState({});

    const { isLoggedIn, isAdmin } = React.useContext(AuthContext)
    const { currentQueryIds, currentQuery, actions: {setUploadModalOpen, search, setQuery, setOfferQty, setOfferPrice} } = React.useContext(ManageContext)
    const { productData, actions: {updateProducts} } = React.useContext(ProductsContext);

    const pushUpdates = () => {
        const updatePackage = {};
        const productIDs = Object.keys(updates);
        productIDs.forEach(id => {
            updatePackage[id] = {...productData[id], ...updates[id]}
        })
        updateProducts(updatePackage, (failed, succeeded) => {
            setUpdates(failed);
        })
    }

    const addUpdate = (productId, edits) => {
        setUpdates({...updates, [productId]: {...updates[productId], ...edits}})
    }

    const downloadCatalogue = async () => {
        const data = await Api.Get("/download/catalogue");
        if (data.url) {
            downloadFile('Catalogue.xlsx', data.url)
        }
    }
    const downloadOffers = async () => {
        const data = await Api.Get("/download/offers");
        if (data.url) {
            downloadFile('Offers.xlsx', data.url);
        }
    }

    const setQty = (offerId, qty) => {
        setOfferQty(offerId, qty);
    }

    const setPrice = (offerId, price) => {
        setOfferPrice(offerId, price);
    }

    const setSort = (col) => {
        const reverse = col === currentQuery.orderBy ? !currentQuery.reverse : false;
        setQuery({orderBy: col, reverse});
    }



    if (!isLoggedIn || !isAdmin) {
        return (
            <div className="screen">
                <div className="store-center">
                    No Access
                </div>
            </div>
        )
    }
    return (
        <div style={{width: '100%'}}>

            <div style={{alignSelf:'stretch', height: 30, backgroundColor:'lightgrey', display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between', paddingLeft: 10, paddingRight: 20}}>
                <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                    <span onClick={() => setUploadModalOpen(true)} style={{cursor:'pointer', marginRight: 20}}>Upload File</span>
                    <span onClick={() => downloadCatalogue()} style={{cursor:'pointer', marginRight: 20}}>Download Catalogue</span>
                    <span onClick={() => downloadOffers()} style={{cursor:'pointer', marginRight: 20}}>Download Offers</span>
                    {Object.keys(updates).length > 0 && <span style={{cursor:'pointer', marginRight: 20}} onClick={() => pushUpdates()}>Save</span>}
                </div>
                <div style={{display:'flex', flexDirection:'row'}}>
                    <input placeholder="search" id="manage-search" value={currentQuery.searchTerm || ''} onChange={e => search(e.target.value)} />
                    { currentQuery.searchTerm !== "" && currentQuery.searchTerm !== null && <div onClick={() => search(null)} className="button cancel">Clear</div>}
                </div>
                <Paginator />
            </div>

            <table id="manage-table">
                <thead>
                    <tr>
                        <th style={{cursor:"pointer"}} onClick={() => setSort('id')}>Product ID</th>
                        <th style={{cursor:'pointer'}} onClick={() => setSort('description')}>Description</th>
                        <th style={{cursor:'pointer'}} onClick={() => setSort('size')}>Size</th>
                        <th style={{cursor:'pointer'}} onClick={() => setSort('case')}>Case Size</th>
                        <th style={{cursor:'pointer'}} onClick={() => setSort('pallet')}>Pallet</th>
                        <th style={{cursor:'pointer'}} onClick={() => setSort('ean')}>EAN</th>
                        <th style={{cursor:'pointer'}} onClick={() => setSort('occ')}>OCC</th>
                        <th style={{cursor:'pointer'}} onClick={() => setSort('cat_one')}>Category One</th>
                        <th style={{cursor:'pointer'}} onClick={() => setSort('cat_two')}>Category Two</th>
                        <th style={{cursor:'pointer'}} onClick={() => setSort('seasonal')}>Seasonal</th>
                        <th style={{cursor:'pointer'}} onClick={() => setSort('frozen')}>Frozen</th>
                        <th style={{cursor:'pointer'}} onClick={() => setSort('listed')}>Listed</th>
                        <th style={{cursor:'pointer'}} onClick={() =>{}}>Supplier Code</th>
                        <th style={{cursor:'pointer'}} onClick={() => {}}>Offer Source</th>
                        <th style={{cursor:'pointer'}} onClick={() => {}}>Offer Quantity</th>
                        <th style={{cursor:'pointer'}} onClick={() => {}}>Offer Cost</th>
                        <th style={{cursor:'pointer'}} onClick={() => {}}>Offer Sell</th>
                        <th style={{cursor:'pointer'}} onClick={() => {}}>Offer Mgn %</th>
                        <th style={{cursor:'pointer'}} onClick={() => {}}>Offer End</th>
                        <th style={{cursor:'pointer'}} onClick={() => {}}>Offer Start</th>
                        <th style={{cursor:'pointer'}} onClick={() => {}}>Offer Alive</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        currentQueryIds.map((id, i) => {
                            const product = productData[id] || null;
                            if (product === null) return null;

                            if (product.ox === null || product.ox.length === 0) {
                                return (
                                    <ProductRow index={1} addUpdate={addUpdate} product={product} updates={updates[product.id] || {}} offer={null} />
                                    )
                            } else {
                                const offers = product.ox.sort((a,b) => a.index < b.index ? 0 : 1);
                                return (
                                    <>
                                        <ProductRow selectedOffer index={1} setPrice={setPrice} setQty={setQty} addUpdate={addUpdate} product={product} updates={updates[product.id] || {}} offer={offers[0]} />
                                        {
                                            offers.slice(1, offers.length).map((o,oi) => <ProductRow setPrice={setPrice} setQty={setQty} index={1+oi} addUpdate={addUpdate} product={null} offer={o} key={oi} />)
                                        }
                                    </>
                                )
                            }
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}

const ProductRow = props => {
    const { offer, product, updates } = props;

    return (
        <tr>
            
            {
                product === null ? 
                <>
                    <td style={{backgroundColor:'lightgrey'}} />
                    <td style={{backgroundColor:'lightgrey'}} />
                    <td style={{backgroundColor:'lightgrey'}} />
                    <td style={{backgroundColor:'lightgrey'}} />
                    <td style={{backgroundColor:'lightgrey'}} />
                    <td style={{backgroundColor:'lightgrey'}} />
                    <td style={{backgroundColor:'lightgrey'}} />
                    <td style={{backgroundColor:'lightgrey'}} />
                    <td style={{backgroundColor:'lightgrey'}} />
                    <td style={{backgroundColor:'lightgrey'}} />
                    <td style={{backgroundColor:'lightgrey'}} />
                    <td style={{backgroundColor:'lightgrey'}} />
                </>
                :
                <>
                    <td>{product ? product.id : ''}</td>
                    { [
                        {
                            dp:'description',
                            typ: 'text'
                        },
                        {
                            dp: 'size',
                            typ: 'text'
                        },
                        {
                            dp: 'case',
                            typ: 'int'
                        },
                        {
                            dp:'pallet',
                            typ:'int',
                        },
                        {
                            dp: 'ean',
                            typ: 'int'
                        },
                        {
                            dp: 'occ',
                            typ: 'int'
                        },
                        {
                            dp: 'category',
                            typ: 'text'
                        },
                        {
                            dp: 'categoryTwo',
                            typ: 'text'
                        },
                        {
                            dp: 'isSeasonal',
                            typ: 'bool'
                        },
                        {
                            dp: 'isFrozen',
                            typ: 'bool'
                        },
                        {
                            dp: 'listed',
                            typ:'bool'
                        }
                        
                    ].map(({dp, typ}, i) => {
                        return (
                            <ProductRowCell
                                key={i}
                                dataPoint={dp}
                                cellid={`${product.id}_${props.index}_${dp}`}
                                addUpdate={val => props.addUpdate(product.id, {[dp]: val})}
                                dataType={typ} 
                                value={dp in updates ? updates[dp] : product[dp]}
                                updated={dp in updates && updates[dp] !== product[dp]} 
                                />
                        )

                    })
                    }
                </>
            }
            
            <td className={`${props.selectedOffer ? 'selected-offer' : 'unselected-offer'}`}>{offer?.onwardRef}</td>
            <td className={`${props.selectedOffer ? 'selected-offer' : 'unselected-offer'}`}>{offer?.scheme}</td>
            {false && <AlteringStockCount setQty={props.setQty} offer={offer} selectedOffer={props.selectedOffer} />}
            <td className={`${props.selectedOffer ? 'selected-offer' : 'unselected-offer'}`}>{offer?.quantity ? offer.quantity : null}</td>
            <td className={`${props.selectedOffer ? 'selected-offer' : 'unselected-offer'}`}>{offer?.cost ? offer.cost.toFixed(2) : null}</td>
            {false && <AlteringPrice setPrice={props.setPrice} offer={offer} selectedOffer={props.selectedOffer} />}
            <td className={`${props.selectedOffer ? 'selected-offer' : 'unselected-offer'}`}>{offer?.price ? offer.price.toFixed(2) : null}</td>
            <td className={`${props.selectedOffer ? 'selected-offer' : 'unselected-offer'}`}>{offer ? (((offer.price - offer.cost) / offer.price) * 100).toFixed(1) : ''}</td>
            <td className={`${props.selectedOffer ? 'selected-offer' : 'unselected-offer'}`}>{offer?.startDate}</td>
            <td className={`${props.selectedOffer ? 'selected-offer' : 'unselected-offer'}`}>{offer?.endDate}</td>
            <td className={`${props.selectedOffer ? 'selected-offer' : 'unselected-offer'}`}>{offer?.alive === true ? 'Yes' : 'No'}</td>
        </tr>
    )
}


const AlteringPrice = props => {
    const { offer } = props;
    const [editing, setEditing] = useState(false);
    const [editVal, setEditVal] = useState(offer ? offer.price : '');
    const [error, setError] = useState(false);

    const changeVal = v => {
        v = v.split("").filter(x => ['0','1','2','3','4','5','6','7','8','9','.'].indexOf(x) !== -1);
        let twoParts = v.join("").split('.').slice(0,2).join('.');
        if (twoParts.indexOf(".") === 0) {
            twoParts = '0' + twoParts;
        }
        setEditVal(twoParts);
    }

    const save = e => {
        e.preventDefault();
        e.stopPropagation();
        let asFloat = parseFloat(parseFloat(editVal).toFixed(2));

        if (isNaN(asFloat) || asFloat < 0) {
            setError(true);
            e.target.focus();
            return
        } else {
            setError(false);
            setEditing(false);
            props.setPrice(offer.id, asFloat);
            e.target.blur();
        }
    }

    if (editing) {
        return (
            <td className={`${props.selectedOffer ? 'selected-offer' : 'unselected-offer'}`}>
                <input onBlur={save} style={{width: 50}} value={editVal.toString()} onChange={e => changeVal(e.target.value)} />
            </td>
        )
    }
    return (
        <td onClick={() => setEditing(true)} className={`${props.selectedOffer ? 'selected-offer' : 'unselected-offer'}`} style={{cursor:'pointer', color: error? 'red': "black"}}>{editVal > -1 ? editVal : 'OGNG'}</td>
    )
}


const AlteringStockCount = props => {
    const { offer } = props;
    const [editing, setEditing] = useState(false);
    const [editVal, setEditVal] = useState(offer ? offer.quantity : -1);
    const [error, setError] = useState(false);

    const changeVal = v => {
        v = v.split("").filter(x => ['0','1','2','3','4','5','6','7','8','9','-'].indexOf(x) !== -1);
        if (v.indexOf('-') > 0) {
            v = v.filter(x => x !== '-');
        }
        setEditVal(v.join(""));
    }

    const save = e => {
        e.preventDefault();
        e.stopPropagation();
        let asInt = parseInt(editVal);
        if (isNaN(asInt)) {
            setError(true);
            e.target.focus();
            return
        } else {
            if (asInt < -1) {
                asInt = -1;
                setEditVal("-1");
            }
            setError(false);
            setEditing(false);
            props.setQty(offer.id, asInt);
            e.target.blur();
        }
    }

    if (editing) {
        return (
            <td className={`${props.selectedOffer ? 'selected-offer' : 'unselected-offer'}`}>
                <input onBlur={save} style={{width: 50}} value={editVal.toString()} onChange={e => changeVal(e.target.value)} />
            </td>
        )
    }
    return (
        <td onClick={() => setEditing(true)} className={`${props.selectedOffer ? 'selected-offer' : 'unselected-offer'}`} style={{cursor:'pointer', color: error? 'red': "black"}}>{editVal > -1 ? editVal : 'OGNG'}</td>
    )
}

class ProductRowCell extends React.Component {
    state = {
        editingValue: null,
        finalValue: null,
        editing: false
    }

    componentDidUpdate(prevprops, prevstate) {
        if (this.state.finalValue !== null && this.state.finalValue !== prevstate.finalValue) {
            this.props.addUpdate(this.state.finalValue);
        }
    }

    didBlur = () => {
        document.getElementById(this.props.cellid).removeEventListener('keypress', this.onEnter);
        this.setState({editing: false, editingValue: null})
    }
    didFocus = () => {
        document.getElementById(this.props.cellid).addEventListener('keypress', this.onEnter)
    }
    onEnter = (e) => {
        if (e.key === 'Enter') {
            this.setState({finalValue: this.state.editingValue})
            this.setState({editing: false})
        }
    }
    setTextVal = (text, typ) => {
        if (typ === 'int') {
            text = text.split('').filter(x => ['0','1','2','3','4','5','6','7','8','9'].indexOf(x) !== -1).join('');
        }
        this.setState({editingValue: text})
    }

    render() {
        const val = this.state.finalValue ? this.state.finalValue : this.state.editingValue === null ? this.props.value : this.state.editingValue;
        const { dataType, updated, cellid } = this.props;

        if (dataType === 'bool') {
            return (
                <td className={`${updated ? 'updated' : ''} ${this.props.dataPoint}`}><div onClick={() => this.props.addUpdate(!this.props.value)} style={{cursor:'pointer'}}>{val ? 'Yes' : 'No'}</div></td>
            )
        }
        if (dataType === 'text' || dataType === 'int') {
            if (!this.state.editing) {
                return (
                    <td className={`${updated ? 'updated' : ''} ${this.props.dataPoint}`}>
                        <div onClick={() => this.setState({editing: true})} style={{cursor:'pointer'}}>{val}</div>
                    </td>
                )
            } else {
                return (
                    <td className={`${updated ? 'updated' : ''} ${this.props.dataPoint}`}>
                        <input id={cellid} onBlur={this.didBlur} onFocus={this.didFocus} autoFocus value={val} onChange={e => this.setTextVal(e.target.value, dataType)} />
                    </td>
                )
            }
        }
    }
}


export default ManageScreen;