import React, { useContext } from 'react';
import Modal from 'react-modal';
import { ManageContext } from './index';
import { modalStyle } from '../modalStyle';
import * as Api from '../../api';
import axios from 'axios';
import { ProductsContext } from '../products';

const ModalContent = props => {
    const { actions: { setUploadModalOpen, execute } } = useContext(ManageContext);
    const { actions: { getProductData, clearCache } } = useContext(ProductsContext)
    const [catalogueFile, setCatalogueFile] = React.useState(null);
    const [offerFile, setOfferFile] = React.useState(null);
    const [didUpdate, setDidUpdate] = React.useState(null);
    const [rejectsUrl, setRejectsUrl] = React.useState(null);
    const [loadingCat, setLoadingCat] = React.useState(false);
    const [loadingOff, setLoadingOff] = React.useState(false);
    const [loadingStock, setLoadingStock] = React.useState(false);
    const [stockFile, setStockFile] = React.useState(null);

    const onCatalogueFile = e => {
        if (e.target.files) {
            setCatalogueFile(e.target.files[0])
        }
    }
    const onOfferFile = e => {
        if (e.target.files) {
            setOfferFile(e.target.files[0])
        }
    }

    const onStockFile = e => {
        if (e.target.files) {
            setStockFile(e.target.files[0])
        }
    }

    const uploadCatalogue = async() => {
        setLoadingCat(true);
        const fd = new FormData();
        fd.append("file", catalogueFile);
        const data = await axios.post(Api.apiUrl + "/upload/catalogue", fd, { withCredentials: true, 'X-Behalf': null, headers: { 'Content-Type': 'multipart/form-data' } });
        if (data.data.ok) {
            getProductData(data.data.updated || [], false);
            setDidUpdate(data.data.updated || [])
            execute();
            clearCache();
            setLoadingCat(false);

        } else if (data.data.rejects && data.data.rejects !== "") {
            setRejectsUrl(data.data.rejects);
            setLoadingCat(false);
        }
    }

    const uploadOffers = async() => {
        const fd = new FormData();
        setLoadingOff(true);
        fd.append("file", offerFile)
        const data = await axios.post(Api.apiUrl + "/upload/offers", fd, { withCredentials: true, "X-Behalf": null, headers: { "Content-Type": "multipart/form-data" } });
        if (data.data.ok) {
            getProductData(data.data.updated || [], false);
            setDidUpdate(data.data.updated || []);
            execute();
            clearCache();
            setLoadingOff(false);
        } else if (data.data.rejects && data.data.rejects !== "") {
            setRejectsUrl(data.data.rejects);
            setLoadingOff(false);
        }
    }

    const uploadStock = async() => {
        const fd = new FormData();
        setLoadingStock(true);
        fd.append("file", stockFile)
        const data = await axios.post(Api.apiUrl + "/upload/stock", fd, { withCredentials: true, "X-Behalf": null, headers: { "Content-Type": "multipart/form-data" } });
        const { data: { products } } = data;
        
        console.log(data);

        if (products.length > 0) {
            getProductData(products, false);
            setDidUpdate(products);
            execute();
            clearCache();
            setLoadingStock(false);
        } else {
            alert("Something Went Wrong")
        }

    }
    if (rejectsUrl !== null) {
        return (
            <div id="uploadModal">
                <h2>Failure</h2>
                <p>Rejects exists - download the rejects file <a download="Rejects.xlsx" href={rejectsUrl}>Here</a></p>
                <div style={{alignSelf:'stretch', display:'flex', flexDirection:'row', justifyContent:'flex-end', alignItems:'center'}}>
                    <div className="button" onClick={() => setUploadModalOpen(false)}>Ok</div>
                </div>
            </div>
        )
    }

    if (didUpdate !== null) {
        return (
        <div id="uploadModal">
            <h2>Success</h2>
            <p>{didUpdate.length} updated or created</p>
            <div style={{alignSelf:'stretch', display:'flex', flexDirection:'row', justifyContent:'flex-end', alignItems:'center'}}>
                <div className="button" onClick={() => setUploadModalOpen(false)}>Ok</div>
            </div>
        </div>
        )
    }

    return (
        <div id="uploadModal">
            <h2>Upload a File</h2>

            <p>Upload a Catalogue file:</p>
            <input type="file" onChange={onCatalogueFile} id="catalogue-upload" />

            {
                catalogueFile !== null && <div onClick={loadingCat ? () => {} : uploadCatalogue} className="button" style={{marginTop: 5}}>{loadingCat ? <Loading /> : 'Upload'}</div>
            }
            
            <p>Upload an Offer file:</p>
            <input type="file" onChange={onOfferFile} id="offer-upload" />
            {
                offerFile !== null && <div onClick={loadingOff ? () => {} : uploadOffers} className="button" style={{marginTop: 5}}>{loadingOff ? <Loading /> : 'Upload'}</div>
            }
            
            <p>Upload an Stock file:</p>
            <input type="file" onChange={onStockFile} id="stock-upload" />
            {
                stockFile !== null && <div onClick={loadingStock ? () => {} : uploadStock} className="button" style={{marginTop: 5}}>{loadingStock ? <Loading /> : 'Upload'}</div>
            }
        </div>
    )
}

class Loading extends React.Component {
    state = {
        messsage: "Loading"
    }

    _interval = null

    step = () => {
        switch(this.state.messsage) {
            case "Loading":
                return "Loading."
            case "Loading.":
                return "Loading.."
            case "Loading..":
                return "Loading..."
            case "Loading...":
                return "Loading"
            default:
                return "Loading"
        }
    }

    componentDidMount() {
        this._interval = setInterval(() => {
            this.setState({messsage: this.step()})
        }, 500);
    }

    componentWillUnmount() {
        clearInterval(this._interval);
    }

    render() {
        return <span>{this.state.messsage}</span>
    }
}

const UploadModal = props => {
    const { uploadModalIsOpen, actions: { setUploadModalOpen } } = useContext(ManageContext);

    return (
        <Modal
            isOpen={uploadModalIsOpen}
            onRequestClose={() => setUploadModalOpen(false)}
            style={modalStyle}
        >
            <ModalContent />
        </Modal>
    )
}

export default UploadModal;
