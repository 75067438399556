import React, { useEffect, useState, useRef } from 'react';
import { AiOutlineHeart, AiFillHeart, AiFillPlusCircle, AiOutlineMinusCircle, AiOutlineShoppingCart } from 'react-icons/ai';
import { MdEdit } from 'react-icons/md'
import { AiOutlineEye } from 'react-icons/ai';
import { BiChevronDownCircle } from 'react-icons/bi';
import { toTitleCase } from '../../utils';
import { PromotionsContext } from '../../layers/promotions';
import { BasketContext } from '../../layers/basket';
import { AuthContext } from '../../layers/auth';
import { ProductsContext } from '../../layers/products';
import moment from 'moment';

const calculatePriceOnProm = (price, group) => {
    switch(group.discountType) {
        case "PERCENTAGE":
            return price * group.discountValue;
        case "HARD_DISCOUNT":
            return price - group.discountValue;
        case "HARD_PRICE":
            return group.discountValue;
        default:
            return price;
    }
}

const promotionGroup = (product, promotions, map) => {
    if (!(product.id in map)) return null;
    const { promId, groupId } = map[product.id];
    if (!(promId in promotions)) return null;
    const prom = promotions[promId];
    if (prom.killed === true || moment(prom.start).isAfter(moment(), 'day') || moment(prom.end).isBefore(moment(), 'day')) {
        return null;
    }
    const group = prom.groups.find(x => x.id === groupId);
    if (group === undefined) return null;
    return group;
}

const promotionMessage = (product, promotions, map) => {
    if (!(product.id in map)) return null;
    const { promId } = map[product.id];
    if (!(promId in promotions)) return null;
    const prom = promotions[promId];
    if (prom.killed === true || moment(prom.start).isAfter(moment(), 'day') || moment(prom.end).isBefore(moment(), 'day')) {
        if (prom.killed === true) {
            return 'Promotion Manually Hidden';
        } else {
            if (moment(prom.start).isAfter(moment(), 'day')) {
                return 'Promotion starts on ' + moment(prom.start).format("DD/MM/YYYY")
            }
            if (moment(prom.end).isBefore(moment(), 'day')) {
                return 'Promotion ended on ' + moment(prom.start).format("DD/MM/YYYY")
            }
        }
    }
    return null;
}

const ProductPricing = props => {
    const { product, offer, offerIsSelected } = props;
    const { map: promMap, promotions } = React.useContext(PromotionsContext);
    const { languageIsPt: pt } = React.useContext(AuthContext);
    const { isAdmin } = React.useContext(AuthContext);

    if (!product.offers) return null //NOT LOGGED IN
    if (!offer) return null //OUT OF STOCK

    let markOutPrice = null;
    let displayPrice = offer.price;

    const promGroup = promotionGroup(product, promotions, promMap);
    if (promGroup) {
        const promPrice = calculatePriceOnProm(offer.price, promGroup);
        markOutPrice = displayPrice;
        displayPrice = promPrice;
    }

    let promMessage = null;
    if (isAdmin === true) {
        promMessage = promotionMessage(product, promotions, promMap);
    }

    return (
        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', color: offerIsSelected ? "blue" : "black"}}>
            <div style={{width: 25}} />

            <div style={{marginTop:props.list ? 0 : '0.5em'}}>
                <div style={{display: 'flex', flexDirection:'row', alignSelf:'stretch', justifyContent:'center'}}>
                    {markOutPrice && <p style={{margin: 0}} className="price slashed">{markOutPrice.toLocaleString('pt-PT', {style:'currency', currency:'EUR'})}</p>}
                    <p style={{margin:0}} className={`price ${markOutPrice !== null && 'promoted'}`}>{displayPrice.toLocaleString('pt-PT', {style:'currency', currency:'EUR'})}</p>
                </div>
                <div style={{display: 'flex', flexDirection:'row', alignSelf:'stretch', justifyContent:'center'}}>
                    {markOutPrice && <p style={{margin: 0}} className="price unit slashed">{(markOutPrice / product.case).toLocaleString('pt-PT', {style:'currency', currency:'EUR'})}</p>}
                    <p style={{margin: 0}} className="price unit">{(displayPrice / product.case).toLocaleString('pt-PT', {style:'currency', currency:'EUR'})} {pt ? 'por unidade' : 'per unit'}</p>
                </div>
                <div className="space"></div>
                {promMessage && 
                    <div style={{display:'flex', flexDirection:'row', alignSelf:'stretch', justifyContent:'center', color:'red', fontSize: '0.8em'}}>
                        {promMessage}
                    </div>
                }
            </div>

            <div onClick={() => props.showOffersList(true)} style={{width: 25, alignSelf:'stretch', display:'flex', justifyContent:'center', alignItems:'center', cursor:'pointer'}}>
                {isAdmin && <BiChevronDownCircle size={20} />}
            </div>
        </div>
    )
}

const ProductBottom = props => {
    const { offer, product } = props;
    const { isLoggedIn, languageIsPt: pt, actions: {setLoginModalOpen} } = React.useContext(AuthContext);
    const { basket, actions: { setBasket } } = React.useContext(BasketContext);
    const [pallet, set_pallet] = useState(false);
    const [input_qty, set_input_qty] = useState(0);
    const i = useRef(null)

    const togglePallet = (t) => set_pallet(t);

    let qty = 0;
    if (offer) {
        if (offer.id in basket) {
            qty = basket[offer.id].quantity
        }
    }

    useEffect(() => {
        if (offer) {
            if (offer.id in basket) {
                qty = basket[offer.id].quantity
                set_input_qty(`${qty}`);
            }
        }
    }, [offer, basket])

    const set = (offer, up=true) => {
        let addition = 1;
        if (!up) {
            addition = -1;
        }
        if (pallet) {
            addition = addition * (product.pallet || 1);
        }
        let newq = qty + addition;
        if (newq < 0) {
            newq = 0;
        }
        setBasket(offer, newq)
    }

    const typeInputQty = (e) => {
        const q = e.target.value.split("").filter(x => ['0','1','2','3','4','5','6','7','8','9'].indexOf(x) !== -1).join('');
        set_input_qty(q);
    }

    const saveInputQty = () => {
        setBasket(offer, parseInt(input_qty));
    }

    const blur = (e) => {
        if (e.keyCode === 13) {
            i.current?.blur();
        }
    }


    if (!isLoggedIn) {
        return (
            <div onClick={() => setLoginModalOpen(true)} style={{cursor: 'pointer', alignSelf: 'stretch', backgroundColor:'white', fontSize: '1em', color:'#FAC043', paddingTop: 3, paddingBottom: 3, marginTop: 10}}>
                {pt ? 'Login ou Registar-se' : 'Log in or Apply'}
            </div>
        )
    }
    if (!product.listed) {
        return (
            <div style={{alignSelf:'stretch', paddingTop: 5, paddingBottom: 3, color:'red', fontSize: '0.8em', fontWeight:'bold', marginTop: 10}}>
                Not Listed (Not Visible to Customer)
            </div>
        )
    }
    if (!offer) {
        return (
            <div style={{alignSelf:'stretch', paddingTop: 5, paddingBottom: 3, color:'red', fontSize: '0.8em', fontWeight:'bold', marginTop: 10}}>
                {pt ? 'Sem stock disponível' : 'Out of Stock'}
            </div>
        )
    }
    if (qty === 0) {
        return (
            <div onClick={() => setBasket(offer, 1)} style={{cursor: 'pointer', alignSelf:'stretch', paddingTop: 3, paddingBottom: 3, color:'white', fontSize: '1em', backgroundColor: '#FAC043', marginTop: 10, borderRadius: 3, marginRight: 5, marginLeft: 5}}>
                {pt ? 'Adicionar ao cesto' : 'Add to Basket'}
            </div>
        )
    }
    return (
        <div style={{flexDirection:'column'}}>
            <div style={{alignSelf:'stretch', paddingTop: 3, paddingBottom: 3, backgroundColor: 'white', marginTop: 10, display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', paddingLeft: 5, paddingRight: 5}}>
                <div onClick={() => set(offer, false)} style={{cursor:'pointer'}}><AiOutlineMinusCircle color="#FAC043" size={22} /></div>
                <input ref={i} onKeyUp={blur} type="text" value={input_qty} onChange={typeInputQty} onBlur={saveInputQty} style={{width: 50, textAlign:'center', backgroundColor:'rgba(0,0,0,0)', border:'none', fontSize: 14, color:'rgb(250, 192, 67)', fontWeight:"bold"}} />
                <div onClick={() => set(offer, true)} style={{cursor: 'pointer'}}><AiFillPlusCircle size={22} color="#FAC043" /></div>
            </div>
        </div>
    )
}

export const ListProduct = props => {
    const { product } = props;
    const { isLoggedIn, languageIsPt: pt, isAdmin } = React.useContext(AuthContext);
    const { faves, actions: { setFave, setEditingProduct }} = React.useContext(ProductsContext);
    const { basket } = React.useContext(BasketContext);
    const [hovering, setHovering] = React.useState(false);
    const [selectedOffer, setSelectedOffer] = React.useState(null);
    

    if (!product) return null;

    const getOffer = () => {
        let offer = null;
        if (selectedOffer !== null) {
            return product.offers.find(x => x.id === selectedOffer);
        }

        if (product.offers !== null) {
            const offers = product.offers.filter(x => x.quantity !== 0);
            if (offers.length > 0) {
                offer = offers[0];
            }
        }
        return offer
    }


    const getOfferQty = () => {
        let qty = 0;
        let offer = getOffer();
        if (offer) {
            if (offer.id in basket) {
                qty = basket[offer.id].quantity
            }
        }
        return qty
    }

    
    const isFave = faves.indexOf(product.id) !== -1;

    const offer = getOffer();
    return (
        <div onMouseOver={() => setHovering(true)} onMouseLeave={() => setHovering(false)} className="product-list">
            {
                hovering &&
                    <div onClick={() => setEditingProduct(product)} className={`${hovering ? 'fade-in' : 'fade-out'}`} style={{cursor:'pointer', position:'absolute', top: 28, left: 28, backgroundColor:'#FAC043', padding: 5, borderRadius: 100, width: 34, height: 34}}>
                            {
                                isAdmin ?
                                    <MdEdit size={30} color={'white'} />
                                :
                                    <AiOutlineEye size={30} color="white" />
                            }
                        </div>
                }
            <img className="product-img" alt={product.description} src={product.image} />
             <div style={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start'}}>
                <p style={{margin: 0}} className="description">{toTitleCase(`${product.description} ${product.case}x${product.size}`)}</p>
                <p style={{margin: 0, color:'grey'}} className="category">{product.category}</p>
                <p style={{margin: 0, fontSize: '0.9em'}} className="category">{product.ean}</p>
             </div>
             <div style={{flex: 1}}>

             </div>
             <div style={{display:'flex', flexDirection:'column', justifyContent:'center'}}>
                {
                    isLoggedIn && 
                    <ProductPricing setSelectedOffer={setSelectedOffer} list offer={offer} product={product} />
                }
               <ProductBottom list product={product} offer={offer} />
             </div>
            {!isLoggedIn && 
                <div className={`fave-heart ${isLoggedIn ? 'hide' : ''}`} onClick={() => setFave(product.id, !isFave)}>
                    {
                        isFave ?
                            <AiFillHeart size={24} color="#E83351" />
                        :
                            <AiOutlineHeart size={24} color="#E83351" />
                    }
                </div>
            }
        </div>
    )
}


const OffersOverlay = props => {
    const { product, offer } = props;
    if (product.ox === null) return null;
    return (
        <div id={`offers-list-${product.id}`} className='offers-list'>
            {
                product.ox.map((off, i) => {
                    return (
                        <div key={i} onClick={() => props.setOffer(off)} className={`row ${offer.id === off.id ? 'selected' : ''}`}>
                            <div className='top'>
                                <span>{off.id}</span>
                            </div>
                            <div style={{display:'flex', flexDirection:'row', alignSelf:'stretch', justifyContent:'space-between'}}>
                                <span>{off.scheme}</span>
                                <span>{off.quantity >= 0 ? `${off.quantity}C/s` : `No limit`}</span>
                                <span>€{off.price.toLocaleString('pt-PT', {style:'currency', currency:'EUR'})}</span>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

const Product = props => {
    const [hovering, setHovering] = React.useState(false);
    const { isLoggedIn, isAdmin } = React.useContext(AuthContext);
    const { faves, actions: { setFave, setEditingProduct }} = React.useContext(ProductsContext)
    const { basket } = React.useContext(BasketContext);
    const [offersListVisible, setOffersListVisible] = React.useState(false);
    const [selectedOffer, setSelectedOffer] = React.useState(null);

    const { product } = props;

    if (product === null) return null;

    const showOffersList = (show) => {
        setOffersListVisible(show);
    }

    const setOffer = id => {
        setOffersListVisible(false);
        if (id === null) {
            setSelectedOffer(null);
            return;
        }
        const auto = autoOffer();
        if (auto !== null) {
            if (auto.id === id) {
                setSelectedOffer(auto);
                return;
            }
        }
        let mtch = product.offers.find(x => x.id === id);
        if (mtch !== undefined) {
            setSelectedOffer(mtch);
        }
    }

    const autoOffer = () => {
        let offer = null;
        if (product.offers !== null) {
            const offers = product.offers.filter(x => x.quantity !== 0);
            if (offers.length > 0) {
                offer = offers[0];
            }
        }
        return offer
    }

    const getOffer = () => {
        if (selectedOffer !== null) {
            return selectedOffer
        }
        return autoOffer();
    }

    const getOfferQty = () => {
        let qty = 0;
        let offer = getOffer();
        if (offer) {
            if (offer.id in basket) {
                qty = basket[offer.id].quantity
            }
        }
        return qty
    }


    const isFave = faves.indexOf(product.id) !== -1;

    const offer = getOffer();

    return (
        <div 
            onMouseOver={() => setHovering(true)} 
            onMouseLeave={() => setHovering(false)}
            className={`product`}>
                {offersListVisible && <OffersOverlay setOffer={offer => setOffer(offer.id)} showOffersList={showOffersList} product={product} offer={offer} />}
                {
                    hovering && 
                        <div onClick={() => setEditingProduct(product)} className={`${hovering ? 'fade-in' : 'fade-out'} hide-mobile`} style={{cursor:'pointer', position:'absolute', top: 90, left: 73, backgroundColor:'#FAC043', padding: 5, borderRadius: 100, width: 34, height: 34}}>
                            {
                                isAdmin ?
                                    <MdEdit className="hide-mobile" size={30} color={'white'} />
                                :
                                    <AiOutlineEye className="hide-mobile" size={30} color="white" />
                            }
                        </div>
                }
                {
                    getOfferQty() > 0 && 
                    <div className="fade-in" style={{display: 'flex', alignItems:'center', justifyContent:'center', backgroundColor: 'rgb(255, 132, 2)', position:'absolute', left: 8, top: 8, borderRadius: 50, width: 32, height: 32}}>
                        <AiOutlineShoppingCart size={18} color="white" />
                    </div>
                }
            {isLoggedIn && 
                <div className={`fave-heart ${hovering ? 'fade-in' : 'fade-out'} ${!isLoggedIn ? 'hide' : ''}`} onClick={() => setFave(product.id, !isFave)}>
                    {
                        isFave ?
                            <AiFillHeart size={24} color="#E83351" />
                        :
                            <AiOutlineHeart size={24} color="#E83351" />
                    }
                </div>
            }
            <div className="centered">
                <div className="img-holder" onClick={() => setEditingProduct(product)} >
                    <img className="product-image" alt={product.description} src={product.image} />
                </div>
            </div>

            <p className="ean">{product.ean}</p>
            <p className="description">{toTitleCase(`${product.description} ${product.case}x${product.size}`)}</p>
            {
                isLoggedIn &&
                <ProductPricing showOffersList={show => showOffersList(show)} offerIsSelected={selectedOffer !== null} offer={offer} product={product} />
            }
            <div style={{flex: 1}} />
            <ProductBottom product={product} offer={offer} />
        </div>
    )
}


export default Product;
