import './App.scss';
import React, { useEffect } from 'react';
import ProductsProvider from './layers/products';
import AuthProvider, { AuthContext } from './layers/auth';
import PromotionsProvider from './layers/promotions';
import Shop from './screens/shop';
import BasketProvider from './layers/basket';
import RepProvider from './layers/rep';
import OrdersProvider from './layers/orders';
import Footer from './screens/footer';
import Header, {MobileMenu, MobileSearch, RepHeader, SplitHeader} from './screens/header';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import CheckoutScreen from './screens/checkout';
import OrdersScreen from './screens/orders';
import ForgotScreen from './screens/forgot';
import ManageScreen from './manage';
import ManageWrapper from './layers/manage';
import RealtimeProvider from './layers/realtime';
import PromotionsScreen from './screens/promotions';
import NewsProvider from './layers/news';
import TandCScreen from './screens/termsAndConditions';
import PrivacyPolicy from './screens/privacyPolicy';
import Home from './screens/home';
import NewsScreen, { NewsArticlePage } from './screens/news';
import logo from './logo.png';
import LanguageBit, { MenuBit } from './layers/auth/languageBit';
import BasketBar from './layers/basket/basketBar';
import EBFT from './screens/ebft';

const TopHeader = props => {
  const {languageIsPt: pt } = React.useContext(AuthContext)
  return (
    <div className="header-container">
    <div className="top-header">

      <div className="top-spacer"></div>
      <div>
        <img src={logo} style={{width: 140}} />
      </div>

      <div className="top-spacer">
        <ul className="contact-header">
          <li><a style={{textDecoration:'none', color:'black'}} href="mailto:info@albanyportugal.com">info@albanyportugal.com</a></li>
          <li className="divide" />
          <li>T: +351 915 416 200</li>
        </ul>
      </div>
    </div>


    <div className="tag">
      <span>{pt ? 'ALBANY PORTUGAL - Fornecedor de Produtos Líder do Reino Unido e Irlanda'.toUpperCase() : 'ALBANY PORTUGAL – SUPPLYING LEADING UK AND IRISH GROCERY PRODUCTS'}</span>
    </div>
  </div>
  )
}

function App() {
  return (
    <AuthProvider>
      <RepProvider>
          <ProductsProvider>
        <ManageWrapper>
            <PromotionsProvider>
              <BasketProvider>
                <OrdersProvider>
                    <RealtimeProvider>
                  <NewsProvider>
                      <div className="App">
                          <MenuBit />
                          <LanguageBit hideSmall />
                          <Router>
                            <MobileMenu />
                            <TopHeader />
                            <Header />
                            <MobileSearch />
                            <BasketBar />
                            <SplitHeader />
                                <Switch>
                                  <Route exact path="/">
                                    <Home />
                                  </Route>
                                  <Route exact path="/shop">
                                    <Shop />
                                  </Route>
                                  <Route exact path="/checkout">
                                    <CheckoutScreen />
                                  </Route>
                                  <Route exact path="/orders">
                                    <OrdersScreen />
                                  </Route>
                                  <Route exact path="/reset">
                                    <ForgotScreen />
                                  </Route>
                                  <Route exact path="/manage">
                                    <ManageScreen />
                                  </Route>
                                  <Route exact path="/promotions">
                                    <PromotionsScreen />
                                  </Route>
                                  <Route exact path="/terms">
                                    <TandCScreen />
                                  </Route>
                                  <Route exact path="/privacy">
                                    <PrivacyPolicy />
                                  </Route>
                                  <Route exact path="/news">
                                    <NewsScreen />
                                  </Route>
                                  <Route exact path="/news/article">
                                    <NewsArticlePage />
                                  </Route>
                                  <Route exact path="/ebft">
                                    <EBFT />
                                  </Route>
                                </Switch>
                            <Footer />
                          </Router>
                          <RepHeader />
                      </div>
                  </NewsProvider>
                    </RealtimeProvider>
                </OrdersProvider>
              </BasketProvider>
            </PromotionsProvider>
        </ManageWrapper>
          </ProductsProvider>
      </RepProvider>
    </AuthProvider>
  );
}

export default App;
