import React, { useContext } from 'react';
import Modal from 'react-modal';
import { ProductsContext } from './index.js';
import { modalStyle } from '../modalStyle';
import { AuthContext } from '../auth/index.js';
import { AiOutlineClose } from 'react-icons/ai';

const ModalContent = props => {
    const { editingProduct, actions: { setEditingProduct, editProduct, uploadPic }} = useContext(ProductsContext);
    const { isAdmin } = useContext(AuthContext)
    const [prod, setProd] = React.useState(editingProduct);
    const [error, setError] = React.useState("")

    const updateProd = (updates) => {
        setProd({...prod, ...updates})
    }

    const submit = () => {
        editProduct(prod, (errors) => setError(errors));
    }

    const handlePic = async e => {
        const file = e.target.files[0];
        const new_url = await uploadPic(prod.ean, file, prod.id);
        setProd({
            ...prod, image: new_url
        });
    }

    if (prod === null) return null;

    if (!isAdmin) {
        return (
            <div id="productModal" style={{position:'relative'}}>
                <div onClick={() => setEditingProduct(null)} style={{cursor:'pointer', position:'absolute', top: 10, right:10, padding: 5}}><AiOutlineClose /></div>
                <div style={{alignSelf:'stretch', display:'flex', flexDirection:'row', justifyContent:'center'}}>
                    <img alt={prod.description} src={prod.image} />
                </div>
            <h2>{prod.description}</h2>
            <div style={{display: "flex", flexDirection:"row", flexWrap: 'wrap', maxWidth: '90vw'}}>
                <div style={{display: "flex", flexDirection: 'column', width: 250, marginRight: 10}}>
                    <div className='row'>
                        <span style={{fontWeight:'bold'}}>Description</span>
                        <span>{prod.description}</span>
                    </div>

                    <div className='row'>
                        <span style={{fontWeight:'bold'}}>Size</span>
                        <span>{prod.size}</span>
                    </div>

                    <div className='row'>
                        <span style={{fontWeight:'bold'}}>EAN</span>
                        <span>{prod.ean}</span>
                    </div>

                    <div className='row'>
                        <span style={{fontWeight:'bold'}}>OCC</span>
                        <span>{prod.occ}</span>
                    </div>

                    <div className='row'>
                        <span style={{fontWeight:'bold'}}>Brand</span>
                        <span>{prod.brand}</span>
                    </div>
                    <div className='row'>
                        <span style={{fontWeight:'bold'}}>Manufacturer</span>
                        <span>{prod.manufacturer}</span>
                    </div>
                </div>
                <div style={{display: "flex", flexDirection: 'column', width: 250, marginRight: 10}}>
                    <div style={{marginBottom: 10, display: 'flex', flexDirection:'column'}}>
                        <span style={{fontWeight:'bold'}}>Category</span>
                        <span>{prod.category}</span>
                    </div>

                    <div style={{marginBottom: 10, display: 'flex', flexDirection:'column'}}>
                        <span style={{fontWeight:'bold'}}>Category Two</span>
                        <span>{prod.categoryTwo}</span>
                    </div>

                    <div style={{marginBottom: 10, display: 'flex', flexDirection:'column'}}>
                        <span style={{fontWeight:'bold'}}>Pallet</span>
                        <span>{prod.pallet}</span>
                    </div>

                    <div style={{marginBottom: 10, display: 'flex', flexDirection:'column'}}>
                        <span style={{fontWeight:'bold'}}>Layer</span>
                        <span>{prod.layer}</span>
                    </div>
                </div>
            </div>

            <span style={{margin: 10, color: 'red'}}>{error}</span>

            <div className="between">
                <div />
                <div onClick={() => setEditingProduct(null)} className="button">
                    OK
                </div>
            </div>
        </div>
        )
    }

    return (
        <div id="productModal">
            <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                <input type="file" accept="image/jpeg, image/jpg" onChange={e => handlePic(e)} style={{display:'none'}} name={`pic-upload-${prod.ean}`} id={`pic-upload-${prod.ean}`} />
                <div style={{marginRight: 20, cursor:'pointer'}}>
                    <label htmlFor={`pic-upload-${prod.ean}`}>
                        <div style={{alignSelf:'stretch', display:'flex', flexDirection:'row', justifyContent:'center'}}>
                            <img alt={prod.description} src={prod.image} style={{width: 80, height: 80}} />
                        </div>
                    </label>
                </div>
                <h2>{prod.description} ID={prod.id}</h2>
            </div>
            <div style={{display: "flex", flexDirection:"row", flexWrap: 'wrap'}}>
                <div style={{display: "flex", flexDirection: 'column', width: 250, marginRight: 10}}>
                    <div style={{marginBottom: 10, display: 'flex', flexDirection:'column'}}>
                        <span onDoubleClick={() => console.log(prod)}>Description</span>
                        <input onChange={e => updateProd({description: e.target.value})} defaultValue={prod.description} />
                    </div>

                    <div style={{marginBottom: 10, display: 'flex', flexDirection:'column'}}>
                        <span>Size</span>
                        <input onChange={e => updateProd({size: e.target.value})} defaultValue={prod.size} />
                    </div>

                    <div style={{marginBottom: 10, display: 'flex', flexDirection:'column'}}>
                        <span>EAN</span>
                        <input onChange={e => updateProd({ean: e.target.value})} defaultValue={prod.ean} />
                    </div>

                    <div style={{marginBottom: 10, display: 'flex', flexDirection:'column'}}>
                        <span>OCC</span>
                        <input onChange={e => updateProd({occ: e.target.value})} defaultValue={prod.occ} />
                    </div>

                    <div style={{marginBottom: 10, display: 'flex', flexDirection:'column'}}>
                        <span>Brand</span>
                        <input onChange={e => updateProd({brand: e.target.value})} defaultValue={prod.brand} />
                    </div>
                    <div style={{marginBottom: 10, display: 'flex', flexDirection:'column'}}>
                        <span>Manufacturer</span>
                        <input onChange={e => updateProd({manufacturer: e.target.value})} defaultValue={prod.manufacturer} />
                    </div>
                    <div style={{marginBottom: 10, display: 'flex', flexDirection:'column'}}>
                        <span>Target Stock</span>
                        <input defaultValue={prod.targetStock.toString()} onChange={e => updateProd({targetStock: parseInt(e.target.value.split("").filter(x => ['0','1','2','3','4','5','6','7','8','9'].indexOf(x) !== -1).join(''))})} />
                    </div>
                </div>
                <div style={{display: "flex", flexDirection: 'column', width: 250, marginRight: 10}}>
                    <div style={{marginBottom: 10, display: 'flex', flexDirection:'column'}}>
                        <span>Category</span>
                        <input onChange={e => updateProd({category: e.target.value})} defaultValue={prod.category} />
                    </div>

                    <div style={{marginBottom: 10, display: 'flex', flexDirection:'column'}}>
                        <span>Category Two</span>
                        <input onChange={e => updateProd({categoryTwo: e.target.value})} defaultValue={prod.categoryTwo} />
                    </div>

                    <div style={{marginBottom: 10, display: 'flex', flexDirection:'column'}}>
                        <span>Pallet</span>
                        <input onChange={e => updateProd({pallet: parseInt(e.target.value.split('').filter(x => ['0','1','2','3','4','5','6','7','8','9'].indexOf(x) !== -1).join(''))})} defaultValue={prod.pallet.toString()} />
                    </div>

                    <div style={{marginBottom: 10, display: 'flex', flexDirection:'column'}}>
                        <span>Layer</span>
                        <input onChange={e => updateProd({layer: parseInt(e.target.value.split('').filter(x => ['0','1','2','3','4','5','6','7','8','9'].indexOf(x) !== -1).join(''))})} defaultValue={prod.layer.toString()} />
                    </div>

                    <div style={{marginBottom: 10, display: 'flex', flexDirection:'column'}}>
                        <span>Is Frozen</span>
                        <input type="checkbox" defaultChecked={prod.isFrozen} onChange={e => updateProd({isFrozen: e.target.checked})} />
                    </div>
                    <div style={{marginBottom: 10, display: 'flex', flexDirection:'column'}}>
                        <span>Is Seasonal</span>
                        <input type="checkbox" defaultChecked={prod.isSeasonal} onChange={e => updateProd({isSeasonal: e.target.checked})} />
                    </div>
                    <div style={{marginBottom: 10, display: 'flex', flexDirection:'column'}}>
                        <span>Is Catering</span>
                        <input type="checkbox" defaultChecked={prod.isCatering} onChange={e => updateProd({isCatering: e.target.checked})} />
                    </div>
                    <div style={{marginBottom: 10, display: 'flex', flexDirection:'column'}}>
                        <span>Is Listed</span>
                        <input type="checkbox" defaultChecked={prod.listed} onChange={e => updateProd({listed: e.target.checked})} />
                    </div>
                    {
                        <div style={{marginBottom: 10, display: 'flex', flexDirection:'column'}}>
                            <span>Is Top Pick</span>
                            <input type="checkbox" defaultChecked={prod.isTopPick} onChange={e => updateProd({isTopPick: e.target.checked})} />
                        </div>
                    }
                </div>
            </div>

            <span style={{margin: 10, color: 'red'}}>{error}</span>

            <div className="between">
                <div onClick={() => setEditingProduct(null)} className="button cancel">
                    Cancel
                </div>
                <div onClick={() => submit()} className="button large">
                    Save
                </div>
            </div>
        </div>
    )
}


const ProductModal = props => {
    const { editingProduct, actions: { setEditingProduct }} = useContext(ProductsContext);

    return (
        <Modal
            isOpen={editingProduct !== null}
            onRequestClose={() => setEditingProduct(null)}
            style={modalStyle}
        >
            <ModalContent />
        </Modal>
    )
}

export default ProductModal;