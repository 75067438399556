import React from 'react';
import Screen from './screen';
import Proms from'./promotionCaro';
import { NewsContext } from '../layers/news';
import { Article, NewsArticle } from './news';
import { Link } from 'react-router-dom';
import Product from './shop/product';
import { ProductsContext } from '../layers/products';
import Supermarket from '../spmkt.jpg';
import Marquee from "react-fast-marquee";
import { AuthContext } from '../layers/auth';


const BrandsMarquee = props => {
    const { brandSugg, actions: { setQuery } } = React.useContext(ProductsContext);
    return (
        <div className="brands-holder" style={{alignSelf:'stretch', flexDirection:'row', overflow:'hidden'}}>
            <Marquee style={{width: '100%'}}>
                {
                    brandSugg.map((brand, i) => <Link onClick={() => setQuery({searchTerm: brand.term})} style={{marginRight: 20}} to={`/shop?search=${brand.term}`}><img style={{maxHeight: 100}} src={brand.img} alt={brand.name} /></Link>)
                }
            </Marquee>
        </div>
    )
}

const TopPicksBar = props => {
    const { topPicks, tick } = React.useContext(ProductsContext);
    const { languageIsPt: pt } = React.useContext(AuthContext);
    const [sTick, setTick] = React.useState(0);

    React.useEffect(() => {
        setTick(tick);
    }, [tick])

    return (
        <div style={{display:'flex', flexDirection:'column', alignSelf:'stretch', alignItems:'center'}}>
            <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignSelf:'stretch'}}>
                <div className="grid homepage" style={{alignSelf:"stretch"}}>
                    {
                        topPicks !== null && 
                        Object.keys(topPicks).sort((a,b) => 0.5 - Math.random()).slice(0, window.innerWidth < 900 ? 4 : 5).map((id, i) => <Product product={topPicks[id]} key={i} />)
                    }
                </div>
            </div>
            <div style={{alignSelf:'stretch', flexDirection:'row', display:'flex', justifyContent:'flex-end'}}>
                <Link to="/shop">
                    <div style={{marginRight: 20}} className="cas-button">{pt ? 'Mais produtos' : 'More Products'}</div>
                </Link>
            </div>
        </div>
    )
}


const AboutUsBar = props => {
    const { languageIsPt: pt } = React.useContext(AuthContext);
    return (
        <div className="about-us-holder">
            <div className='about-us-homepage'>
                <img src={Supermarket} />
                <div className="text">
                    <p style={{marginTop: 0}}>{pt ? 'O Grupo Albany é composto por três organizações comerciais importantes: Albany Products Ltd UK, Albany Ireland e Albany Portugal.': 'Albany Portugal operates direct supplies to retailers and wholesale distributors throughout Portugal. With weekly deliveries directly from Ireland, an extensive range, local sales support and a consistent supply and delivery rate of at least 95%, Albany Portugal is ideally placed to supply all your leading UK and Irish branded grocery products.'}</p>
                </div>
            </div>
        </div>
    )
}

const Home = props => {
    const { actions: { pushArticle , loadNews } } = React.useContext(NewsContext);
    const [editingArticle, setEditingArticle] = React.useState(null);
    const { languageIsPt: pt, isAdmin } = React.useContext(AuthContext);

    React.useEffect(() => {
        loadNews();
    }, []);

    return (
        <Screen title={pt ? 'Bem-Vindos a Albany Portugal' : "Welcome to Albany Portugal"}>
            <div style={{flexDirection:'column', alignItems:'flex-start'}}>
                <div className='home-page-area'>
                    <div className="title">
                        <h2>{pt ? 'Promoções'.toUpperCase(): 'PROMOTIONS'}</h2>
                    </div>
                    <Proms links />
                </div>

                <div className='home-page-area'>
                    <div className="title">
                        <h2>{pt ? 'Top Escolhas'.toUpperCase() : 'TOP PICKS'}</h2>
                    </div>
                    <TopPicksBar />
                </div>

                <div className='home-page-area'>
                    <div className="title">
                        <h2>{pt ? 'Sobre Nós'.toUpperCase() : 'About Us'}</h2>
                    </div>
                    <AboutUsBar />
                </div>
                
                <div className='home-page-area'>
                    <div className="title">
                        <h2>{pt ? 'As nossas marcas'.toUpperCase() : 'OUR BRANDS'}</h2>
                    </div>
                    <BrandsMarquee />
                </div>
            </div>
        </Screen>
    )
}

export default Home