import React, { useContext } from 'react';
import Modal from 'react-modal';
import { AuthContext } from './index';
import { modalStyle } from '../modalStyle';

const ModalContent = props => {
    const { account, languageIsPt: pt, actions: { logout } } = useContext(AuthContext);
    return (
        <div id="loginModal">
            <h2>{pt ? 'A Sua Conta' : 'Your Account'}</h2>
            <p>{account.name}</p>
            <p>{account.emailAddress}</p>
            <p>{account.company}</p>

            <div onClick={logout} className="button warn">{pt ? 'Sair' : 'Log Out'}</div>
        </div>
    )
}

const AccountModal = props => {
    const { accountModalIsOpen, actions: { setShowAccountModal } } = useContext(AuthContext);

    return (
        <Modal
            isOpen={accountModalIsOpen}
            onRequestClose={() => setShowAccountModal(false)}
            style={modalStyle}
        >
            <ModalContent />
        </Modal>
    )
}

export default AccountModal;