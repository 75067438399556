
export function toTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  export function listToggle(lst, val) {
      if (lst.indexOf(val) === -1) {
          return lst.concat([val])
      }
      return lst.filter(x => x !== val);
  }

  export function removeKeyFromObj(obj, key) {
    const out = {};
    const kys = Object.keys(obj);
    for (var i=0; i < kys.length; i++) {
      if (kys[i] !== key) {
        out[kys[i]] = obj[kys[i]]
      }
    }
    return out;
  }

  export function downloadFile(fileName, url) {
    const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
  }