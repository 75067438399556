import React from 'react';
import Screen from './screen';
import queryString from 'query-string';
import * as Api from '../api';
import axios from 'axios';
import { AuthContext } from '../layers/auth';
import { Redirect } from 'react-router-dom';

const upperRegex = /[A-Z]/;
const lowerRegex = /[a-z]/;
const numRegex = /\d/;
const legth = p => p.length >= 8;

const ForgotScreen = props => {
    const [password, setPassword] = React.useState('');
    const [confirmPass, setConfirmPass] = React.useState('')
    const [error, setError] = React.useState(false);
    const [didUpdate, setDidUpdate] = React.useState(false);
    const { languageIsPt: pt, actions : {setLoginModalOpen} } = React.useContext(AuthContext)

    const q = queryString.parse(window.location.search);
    if (!q.t || q.t.length === 0) {
        return (
            <Screen title={pt ? 'Algo correu mal' : "Something Went Wrong"} />
        )
    }

    const validate = () => {
        if (!upperRegex.test(password)) {
            setError(pt ? 'Password tem que conter maiúsculas' : "Password must contain Uppercase Letters")
            return false;
        }
        if (!lowerRegex.test(password)) {
            setError(pt ? 'Password tem que conter minúsculas' : "Password must contain Lowercase Letters")
            return false;
        }
        if (!numRegex.test(password)) {
            setError(pt ? 'Password tem que conter números' : "Password must contain numbers")
            return false
        }
        if (!legth(password)) {
            setError(pt ? 'Password tem conter mais de 8 caracteres' : "Password must be 8+ Characters long")
            return false;
        }
        if (password !== confirmPass) {
            setError(pt ? 'Password não coincidem' : "Passwords do not match");
            return false;
        }
        return true;
    }

    const submit = async () => {
        if (!validate()) {
            return;
        }

        try {
            await axios.post(Api.apiUrl + "/reset", { password }, { headers: {Authorization: q.t}});
            setDidUpdate(true)
            setLoginModalOpen(true, pt ? 'A sua password foi actualizada com sucesso, faça login com os seus novos dados de acesso' : "You've successfully updated your password - please login in with your new details")
        } catch(e) {

        }
    }

    if (didUpdate) {
        return <Redirect to="/shop" />
    }

    return (
        <Screen title={pt ? 'Repor a password' : "Reset your Password"}>
            <div style={{display:'flex', flexDirection:'column', alignSelf:'stretch', flex:1, justifyContent:'center', alignItems:"center"}}>
                <p>{pt ? 'Crie nova password em baixo e carregue em salvar. Após actualização de nova password, necessita fazer login novamente' : 'Create a new password below and click save. Once you\'re new password is set, you\'ll need to sign in again'}</p>

                <div style={{width: 300, display:'flex', flexDirection:'column'}}>
                    <span>{pt ? 'Criar password' : 'Create a Password'}</span>
                    <input value={password} onChange={e => setPassword(e.target.value)} className="email" type="password" />

                    <span>{pt ? 'Confirmar password' : 'Confirm your Password'}</span>
                    <input value={confirmPass} onChange={e => setConfirmPass(e.target.value)} className="email" type="password" />


                    {
                        error && <p style={{color:'red'}}>{error}</p>
                    }
                    <div style={{display:'flex', flexDirection:'row', alignSelf:'stretch', justifyContent:'flex-end', marginTop: 20}}>
                        <div onClick={submit} className="button">{pt ? 'Salvar' : 'Save'}</div>
                    </div>
                </div>
            </div>
        </Screen>
    )
}

export default ForgotScreen;