import React from 'react';
import {
    Link,
    NavLink
} from 'react-router-dom';
import { FaUser, FaShoppingBasket } from 'react-icons/fa'
import { ProductsContext } from '../layers/products';
import { FiSettings } from 'react-icons/fi';
import { BasketContext } from '../layers/basket';
import { AuthContext } from '../layers/auth';
import { RepContext } from '../layers/rep';
import LanguageBit from '../layers/auth/languageBit';
import Select from 'react-select';
import { downloadFile } from '../utils';
import * as Api from '../api';
import { OrdersContext } from '../layers/orders';

export const MobileMenu = props => {
    const { languageIsPt: pt, isLoggedIn, isAdmin, mobileMenuOpen: open, actions: { setMobileMenuOpen, setLoginModalOpen, setShowAccountModal }} = React.useContext(AuthContext);
    const [closed, setClosed] = React.useState(false);
    const [stateOpen, setOpen] = React.useState(false);

    React.useEffect(() => {
        if (open === false && stateOpen === true) {
            setClosed(true);
            setOpen(false);
        } else {
            if (open === true && stateOpen === false) {
                setOpen(true);
                setClosed(false)
            }
        }
    }, [open, stateOpen])

    return (
        <>
        {open && <div onClick={() => setMobileMenuOpen(false)} className={`mobile-menu-backdrop ${closed ? 'closed' : ''} ${stateOpen ? 'open' : ''}`}></div>}
        <div className={`mobile-menu ${closed ? 'closed' : ''} ${stateOpen ? 'open' : ''}`}>
            <div style={{height: 100}} />
            <Link to='/'><div onClick={() => setMobileMenuOpen(false)} className="option">{pt ? 'Início'.toUpperCase() : 'HOME'}</div></Link>
            {isLoggedIn && <Link to='/orders'><div onClick={() => setMobileMenuOpen(false)} className='option'>{pt ? 'MINHAS ENCOMENDAS' : 'MY ORDERS'}</div></Link>}
            <Link to='/shop'><div onClick={() => setMobileMenuOpen(false)} className="option">{pt ? 'LOJA' : 'SHOP'}</div></Link>
            <Link to='/news'><div onClick={() => setMobileMenuOpen(false)} className="option">{pt ? 'Novidades'.toUpperCase() : 'NEWS'}</div></Link>
            <Link to="/ebft"><div onClick={() => setMobileMenuOpen(false)} className='option'>EBFT</div></Link>
            {isLoggedIn && <div className='option' onClick={() => setShowAccountModal(true)}>MY ACCOUNT</div>}
            {!isLoggedIn && <div className='option' onClick={() => setLoginModalOpen(true)}>LOGIN OR SIGNUP</div>}
            <LanguageBit style={{left: 20, bottom: 20, right: 'auto', top:'auto'}} />
        </div>
        </>
    )
}



export const RepHeader = props => {
    const { isAdmin } = React.useContext(AuthContext);
    const { customers, behalfOf, actions: {behalf, setCustomerModalShowing, setCreateCustomerModalShowing, setMatrixModalShowing} } = React.useContext(RepContext)
    if (!isAdmin) return null;

    const downloadBulkStock = async () => {
        const data = await Api.Get("/report/bulk");
        if (data.url) {
            downloadFile('Bulk Stock Report.xlsx', data.url)
        }
    }

    const notListed = async () => {
        const data = await Api.Get("/report/not_listed");
        if (data.url) {
            downloadFile("Not Listed Report.xlsx", data.url);
        }
    }

    return (
        <div className="rep-header">
            <div style={{display: "flex", flexDirection:'row', alignItems:'center'}}>
                <div style={{flex: 1, width: 200, maxWidth: 200, paddingLeft: 10}}>
                    <Select
                        menuPlacement='top'
                        defaultValue={{label: "NONE", value: null}}
                        onChange={customer => behalf(customer.value)}
                        options={[{label: "NONE", value: null}].concat(customers.map((customer, i) => ({label: customer.username, value: customer.id})))}
                    />
                </div>
                {
                    behalfOf !== null && 
                    <div onClick={() => setCustomerModalShowing(true)} style={{marginLeft: 10, padding:3}}>
                        <FiSettings size={30} color="white" />
                    </div>
                }

                <div onClick={downloadBulkStock} style={{alignSelf:'stretch', display:'flex', justifyContent:'center', alignItems:'center', cursor:'pointer', cursor:'pointer', color:'white', fontSize: '1em', padding: 10}}>
                    Bulk Stock Report
                </div>
                <div onClick={notListed} style={{alignSelf:'stretch', display:'flex', justifyContent:'center', alignItems:'center', cursor:'pointer', cursor:'pointer', color:'white', fontSize: '1em', padding: 10}}>
                    Not Listed Report
                </div>
                
                
                <div onClick={() => setMatrixModalShowing(true)} style={{alignSelf:'stretch', display:'flex', justifyContent:'center', alignItems:'center', cursor:'pointer', cursor:'pointer', color:'white', fontSize: '1em', padding: 10}}>Sales Matrix</div>
            </div>
            <div>
                <span onClick={() => setCreateCustomerModalShowing(true)} style={{color:'white', fontSize:"1em", cursor:'pointer', marginRight: 10}}>Create a Customer</span>
            </div>

        </div>
    )
}

export const SplitHeader = props => {
    const { actions: {getCount }} = React.useContext(BasketContext);
    const { isLoggedIn, languageIsPt: pt, isAdmin, actions: {setLoginModalOpen, setShowAccountModal} } = React.useContext(AuthContext);
    const { actions: { setQuery }} = React.useContext(ProductsContext)
    const [newSearchTerm, setNewSearchTerm] = React.useState("")

    const addEnterListener = (e) => {
        document.addEventListener("keypress", onEnter);
    }

    const removeEnterListener = (e) => {
        document.removeEventListener("keypress", onEnter);
    }

    const onEnter = (e) => {
        if (e.key == "Enter") {
            setQuery({searchTerm: newSearchTerm});
            document.getElementById("search-button").click();
        }
    }

    return (
            <div className="split-header" style={{top: 0, fontSize: '0.8em'}}>
                <nav>
                    <ul>
                        <li>
                            <NavLink to="/">{pt ? 'Início'.toUpperCase() : 'HOME'}</NavLink>
                        </li>
                        <li className="divide" />
                        <li>
                            <NavLink activeClassName="active-link" to="/shop">{pt ? 'LOJA' : 'SHOP'}</NavLink>
                        </li>
                        {isLoggedIn && <li className="divide" />}
                        {isLoggedIn && 
                            <li>
                                <NavLink activeClassName="active-link" to="/orders">{pt ? 'MINHAS ENCOMENDAS' : 'MY ORDERS'}</NavLink>
                            </li>
                        }
                        {isAdmin && isLoggedIn && <li className="divide hide-mobile" />}
                        {
                            isAdmin && isLoggedIn && 
                            <li className="hide-mobile">
                                <NavLink activeClassName="active-link" to="/manage">CATALOGUE</NavLink>
                            </li>
                        }
                        {
                            isAdmin && isLoggedIn && <li className="divide" />
                        }
                        {
                            isAdmin && isLoggedIn &&
                            <li>
                                <NavLink activeClassName="active-link" to="/promotions">{pt ? 'Promoções'.toUpperCase() : 'PROMOTIONS'}</NavLink>
                            </li>
                        }
                        <li className="divide" />
                        <li>
                            <NavLink activeClassName="active-link" to="/news">{pt ? 'Novidades'.toUpperCase() : 'NEWS'}</NavLink>
                        </li>
                        <li className='divide' />
                        <li>
                            <NavLink activeClassName='active-link' to="/ebft">EBFT</NavLink>
                        </li>
                    </ul>
                </nav>

                <nav>
                    <ul className="search_and_login">
                        <li>
                            <div style={{flexDirection:'row', display:'flex'}}>
                                <input className="top-search-input" onFocus={addEnterListener} onBlur={removeEnterListener} style={{color:'black'}} onChange={e => setNewSearchTerm(e.target.value)} id="search-top" type="text" />
                                <Link id="search-button" onClick={e => setQuery({searchTerm: newSearchTerm})} to={`/shop?search=${newSearchTerm}`}>
                                    <div style={{backgroundColor:"white", alignSelf:'stretch', height: '100%', display:'flex', paddingLeft: 10, paddingRight: 10, color:'#ff8402', justifyContent:'center', alignItems:'center'}}>{pt ? 'Procurar' : 'Search'}</div>
                                </Link>
                            </div>
                        </li>
                        <li className="divide" />
                        {isLoggedIn && getCount() > 0 && 
                            <li>
                                <NavLink to="/checkout">
                                    <div style={{display:'flex', flexDirection:'row', alignItems:'center', cursor:'pointer'}}>
                                        <FaShoppingBasket color="rgb(70, 70, 70)" size={28} />
                                        <span style={{marginLeft: 10, color: 'rgb(70, 70, 70)'}}>{getCount()} items</span>
                                    </div>
                                </NavLink>
                            </li>
                        }
                        {isLoggedIn && getCount() > 0 &&  <li className="divide" />}
                        <li>
                            {
                                isLoggedIn ? 
                                    <div onClick={() => setShowAccountModal(true)} style={{display:'flex', flexDirection:'row', alignItems:'center', cursor: 'pointer', position:'relative'}}>
                                        <FaUser color="rgb(70, 70, 70)" size={28} />
                                    </div>
                                :
                                    <span onClick={() => setLoginModalOpen(true)} style={{marginLeft: 10, color:'rgb(70, 70, 70)', cursor:'pointer'}}>{pt ? 'Login ou Registar-se' : 'Login or Apply for an Account'}</span>
                            }
                        
                        </li>
                    </ul>
                </nav>
            </div>
    )
}

export const MobileSearch = props => {
    const { actions: { setQuery }} = React.useContext(ProductsContext)
    const { languageIsPt: pt } = React.useContext(AuthContext);
    const [newSearchTerm, setNewSearchTerm] = React.useState("")


    const addEnterListener = (e) => {
        document.addEventListener("keypress", onEnter);
    }

    const removeEnterListener = (e) => {
        document.removeEventListener("keypress", onEnter);
    }

    const onEnter = (e) => {
        if (e.key == "Enter") {
            setQuery({searchTerm: newSearchTerm});
            document.getElementById("search-button").click();
        }
    }

    return (
        <div className='mobile-search'>
            <div className='holder'>
                <input className="top-search-input" onFocus={addEnterListener} onBlur={removeEnterListener} style={{color:'black'}} onChange={e => setNewSearchTerm(e.target.value)} id="search-top" type="text" />
                <Link style={{alignSelf:'stretch'}} id="search-button" onClick={e => setQuery({searchTerm: newSearchTerm})} to={`/shop?search=${newSearchTerm}`}>
                    <div style={{alignSelf:'stretch', backgroundColor:"white", alignSelf:'stretch', height: '100%', display:'flex', paddingLeft: 10, paddingRight: 10, color:'#ff8402', justifyContent:'center', alignItems:'center'}}>{pt ? 'Procurar' : 'Search'}</div>
                </Link>
            </div>
        </div>
    )
}

const Header = props => {
    const { actions: {getCount }} = React.useContext(BasketContext);
    const { isLoggedIn, languageIsPt: pt, isAdmin, actions: {setLoginModalOpen, setShowAccountModal} } = React.useContext(AuthContext);
    const { actions: { setQuery }} = React.useContext(ProductsContext);
    const { uploadingOrder, actions: { setUploadingOrder }} = React.useContext(OrdersContext)
    const [newSearchTerm, setNewSearchTerm] = React.useState("")

    const addEnterListener = (e) => {
        document.addEventListener("keypress", onEnter);
    }

    const removeEnterListener = (e) => {
        document.removeEventListener("keypress", onEnter);
    }

    const onEnter = (e) => {
        if (e.key == "Enter") {
            setQuery({searchTerm: newSearchTerm});
            document.getElementById("search-button").click();
        }
    }

    return (
            <div className="main-header" style={{top: 0, fontSize: '0.8em'}}>
                <nav>
                    <ul>
                        <li>
                            <NavLink to="/">{pt ? 'Início'.toUpperCase() : 'HOME'}</NavLink>
                        </li>
                        <li className="divide" />
                        <li>
                            <NavLink activeClassName="active-link" to="/shop">{pt ? 'LOJA' : 'SHOP'}</NavLink>
                        </li>
                        {isLoggedIn && <li className="divide" />}
                        {isLoggedIn && <li>
                            <NavLink activeClassName="active-link" to="/orders">{pt ? 'MINHAS ENCOMENDAS' : 'MY ORDERS'}</NavLink>
                        </li>}
                        {isAdmin && isLoggedIn && <li className="divide" />}
                        {
                            isAdmin && isLoggedIn && 
                            <li>
                                <NavLink activeClassName="active-link" to="/manage">{pt ? 'Catálogo'.toUpperCase() : 'CATALOGUE'}</NavLink>
                            </li>
                        }
                        {
                            isAdmin && isLoggedIn && <li className="divide" />
                        }
                        {
                            isAdmin && isLoggedIn &&
                            <li>
                                <NavLink activeClassName="active-link" to="/promotions">{pt ? 'Promoções'.toUpperCase() : 'PROMOTIONS'}</NavLink>
                            </li>
                        }
                        <li className="divide" />
                        <li>
                            <NavLink activeClassName="active-link" to="/news">{pt ? 'Novidades'.toUpperCase() : 'NEWS'}</NavLink>
                        </li>
                        <li className='divide' />
                        <li>
                            <NavLink activeClassName="active-link" to="/ebft">EBFT</NavLink>
                        </li>
                        {isLoggedIn && <li className='divide' />}
                        {isLoggedIn && 
                            <li>
                                <span style={{cursor:'pointer', color:'white'}} onClick={() => setUploadingOrder(true)}>{pt ? 'Visualizar encomendas'.toUpperCase() : 'UPLOAD AN ORDER'}</span>
                            </li>
                        }
                    </ul>
                </nav>
                <nav>
                    <ul>
                        <li>
                            <div style={{flexDirection:'row', display:'flex'}}>
                                <input className="top-search-input" onFocus={addEnterListener} onBlur={removeEnterListener} style={{color:'black'}} onChange={e => setNewSearchTerm(e.target.value)} id="search-top" type="text" />
                                <Link id="search-button" onClick={e => setQuery({searchTerm: newSearchTerm})} to={`/shop?search=${newSearchTerm}`}>
                                    <div style={{backgroundColor:"white", alignSelf:'stretch', height: '100%', display:'flex', paddingLeft: 10, paddingRight: 10, color:'#ff8402', justifyContent:'center', alignItems:'center'}}>{pt ? 'Procurar' : 'Search'}</div>
                                </Link>
                            </div>
                        </li>
                        <li className="divide" />
                        {isLoggedIn && getCount() > 0 && 
                            <li>
                                <NavLink to="/checkout">
                                    <div style={{display:'flex', flexDirection:'row', alignItems:'center', cursor:'pointer'}}>
                                        <FaShoppingBasket color="rgb(70, 70, 70)" size={28} />
                                        <span style={{marginLeft: 10, color: 'rgb(70, 70, 70)'}}>{getCount()} items</span>
                                    </div>
                                </NavLink>
                            </li>
                        }
                        {isLoggedIn && getCount() > 0 &&  <li className="divide" />}
                        <li>
                            {
                                isLoggedIn ? 
                                    <div onClick={() => setShowAccountModal(true)} style={{display:'flex', flexDirection:'row', alignItems:'center', cursor: 'pointer', position:'relative'}}>
                                        <FaUser color="rgb(70, 70, 70)" size={28} />
                                    </div>
                                :
                                    <span onClick={() => setLoginModalOpen(true)} style={{marginLeft: 10, color:'rgb(70, 70, 70)', cursor:'pointer'}}>{pt ? 'Login ou Registar-se' : 'Login or Apply for an Account'}</span>
                            }
                        
                        </li>
                    </ul>
                </nav>
            </div>
    )
}

export default Header