import React from 'react';
import moment from 'moment';
import { OrdersContext } from '../layers/orders';
import { AuthContext } from '../layers/auth';
import queryString from 'query-string';
import Screen from './screen';

const monthTranlations = {
    'January': 'Janeiro',
    'February': 'Fevereiro',
    'Match': 'Março',
    'April': 'Abril',
    'May': 'Maio',
    'June': 'Junho',
    'July': 'Julho',
    'August': 'Agosto',
    'September': 'Setembro',
    'October': 'Outubro',
    'November': 'Novembro',
    'December': 'Dezembro',
}
const dayTranslations = {
    'Monday': 'Segunda-feira',
    'Tuesday': 'Terça-feira',
    'Wednesday': 'Quarta-feira',
    'Thursday': 'Quinta-feira',
    'Friday': 'Sexta-feira',
    'Saturday': 'Sábado',
    'Sunday': 'Domingo',
}

const Order = props => {
    const { orders, cOrders, actions: { setViewingOrder } } = React.useContext(OrdersContext);
    const { languageIsPt: pt } = React.useContext(AuthContext);
    const { id } = props;

    const order = id in orders ? orders[id] : id in cOrders ? cOrders[id] : null;
    if (order === null) return null;

    const orderLines = () => {
        let lines = 0;
        if (order === null) return lines;

        const groups = Object.values(order.lineGroups);
        for (var i=0; i < groups.length; i++) {
            const grp = groups[i];
            if (grp === null) continue
            lines = lines + grp.length;
        }
        return lines;
    }

    const orderValue = () => {
        let value = 0;
        if (order === null) return value;

        const groups = Object.values(order.lineGroups);
        for (var i=0; i < groups.length; i++) {
            const grp = groups[i];
            if (grp === null) continue
            for (var j=0; j < grp.length; j++) {
                const p = grp[j];
                value = value + (p.quantity * p.price);
            }
        }
        return value;
    }

    const getPlaced = () => {
        const m = moment(order.orderTime);
        const time = m.format("HH:mm");
        let weekday = m.format("dddd");
        let date = m.format("Do")
        let month = m.format("MMMM");
        const yr = m.format("YYYY");

        if (pt === true) {
            date = m.format("D") + " de"
            weekday = dayTranslations[weekday];
            month = monthTranlations[month];
        }
        return time + ' - ' + weekday + ' ' + date + ' ' + month + ' ' + yr;
    }

    const { justPlaced } = props;

    return (
        <div style={{display:'flex', alignSelf:'stretch', margin:10, borderRadius: 2, border: `solid ${justPlaced ? 2 : 1}px ${justPlaced ? '#ff8402' : 'lightgrey'}`, flexDirection:"row", alignItems:'flex-start', padding: 10, justifyContent:'space-between', color:'#666'}}>
            <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start', justifyContent:'center', alignSelf:"stretch"}}>
                <span style={{fontWeight:'bold'}}>{pt ? 'Encomenda' : 'Placed'} {order.id}{order.ref ? ` / ${order.ref}` : ''}</span>
                <span style={{color:'#666', fontSize: '0.8em'}}>{pt ? 'Colocada às' : 'Placed'} {getPlaced()}</span>
                {order.byRep !== 0 && order.byRep !== order.userId && <span style={{fontSize: '0.8em'}}>{pt ? 'Encomenda realizada em seu nome, pelo representante de vendas' : 'Placed on your behalf, by your sales rep'}</span>}
            </div>
            <div style={{alignSelf:'stretch', justifyContent:'center', alignItems:'center', display:'flex'}}>
                {order.cancelled ? pt ? 'Cancelada' : 'Cancelled' : order.complete ? pt ? 'Completa' : 'Complete' : pt ? 'Acitva' : 'Active'}
            </div>
            <div>
                <ul style={{flexDirection:'row', display:'flex', listStyleType:'none', alignItems:'center'}}>
                    <li>{orderLines()} {pt ? 'Produtos' : 'Products'}</li>
                    <li className="divide" />
                    <li>{orderValue().toLocaleString('pt-PT', {style:'currency', currency:'EUR'})}</li>
                </ul>
            </div>
            <div style={{display:'flex', justifyContent:'center',alignItems:'center', alignSelf:'stretch'}}>
                <div onClick={() => setViewingOrder(order)} className="button">{pt ? 'Mais' : 'View More'}</div>
            </div>
        </div>
    )
}



const OrdersScreen = props => {
    const { currentQueryCount, orderIds, actions: { getOrders, loadMoreOrders }} = React.useContext(OrdersContext);
    const { isLoggedIn, languageIsPt: pt, actions: {setLoginModalOpen } } = React.useContext(AuthContext);
    const q = queryString.parse(window.location.search);
    let thanks = false;
    if (q.thanks && q.thanks !== '') {
        thanks = q.thanks
    }

    React.useEffect(() => {
        if (isLoggedIn) {
            getOrders();
        }
    }, [isLoggedIn, getOrders])

    if (!isLoggedIn) {
        return (
            <Screen title={pt ? 'Minhas Encomendas' : "My Orders"}>
                <div style={{display:'flex', flexDirection:'row', alignSelf:'stretch', justifyContent:'center', padding: 30, flex:1}}>
                    <p>{pt ? 'Por favor' : 'Please'} <span onClick={() => setLoginModalOpen(true)} style={{cursor:'pointer', fontSize: '1.1em', color:'#FAC043', fontWeight:'bold'}}>{pt ? 'faça login' : 'Log In'}</span> {pt ? ' para ver as suas encomendas' : 'to view your orders'}</p>
                </div>
            </Screen>
        )
    }

    return (
        <Screen title={pt ? 'Minhas Encomendas' : "My Orders"}>
                <div style={{display:'flex', alignSelf:'stretch', flex: 1, flexDirection:'column'}}>
                    {  
                        thanks !== false &&
                        <div style={{margin: 20, fontWeight: 'bold', alignSelf:'stretch', display:'flex', justifyContent:'center', alignItems:'center'}}>{pt ? 'Agradecemos a sua encomenda. Brevemente irá receber um email de confirmação com os detalhes da sua encomenda' : 'Thank you for your order. You will shortly receive an email confirmation with your order details.'}</div>}
                    {
                        orderIds.map((orderId, i) => {
                            return(
                                <Order justPlaced={thanks !== false && parseInt(thanks) === orderId} id={orderId} key={i} />
                            )
                        })
                    }
                    {
                        orderIds.length < currentQueryCount && <div onClick={loadMoreOrders} className="button" style={{margin: 20}}>{pt ? 'Mais' : 'More'}</div>
                    }
                </div>
        </Screen>
    )
}

export default OrdersScreen