import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { PromotionsContext } from '../layers/promotions';
import { ProductsContext } from '../layers/products';
import moment from 'moment';
import { Link } from 'react-router-dom';

const promotionMessage = (prom) => {
    if (prom.killed === true || moment(prom.start).isAfter(moment(), 'day') || moment(prom.end).isBefore(moment(), 'day')) {
        if (prom.killed === true) {
            return 'HIDDEN';
        } else {
            if (moment(prom.start).isAfter(moment(), 'day')) {
                return 'STARTS ' + moment(prom.start).format("DD/MM/YYYY")
            }
            if (moment(prom.end).isBefore(moment(), 'day')) {
                return 'ENDED ' + moment(prom.start).format("DD/MM/YYYY")
            }
        }
    }
    return null;
}


const PromCaro = props => {
    const { promotions } = React.useContext(PromotionsContext);
    const { actions: { setQuery } } = React.useContext(ProductsContext)
    const {small} = props;

    const viewProm = (id) => {
        setQuery({promotionIds: [id], categoryOnes: [], categoryTwos: [], brands: [], searchTerm: null})
    }

    const viewSeasonal = () => {
        setQuery({includeFrozen: false, includeSeasonal: true, includeAmbient: false, searchTerm: null, categoryOnes: [], categoryTwos: [], brands: [], promotionIds: []});
    }

    return (
        <div style={{padding: 10}}>
            <Carousel
                infiniteLoop
                interval={5000}
                showIndicators={false}
                swipeable
                autoPlay
                showStatus={false}
                showThumbs={false}
                >
                {
                    Object.values(promotions).sort((a,b) => (a.sortOrder || 999) > (b.sortOrder || 999)).map((prom, i) => {
                        if (moment(prom.end).isBefore(moment())) return null;
                        const message = promotionMessage(prom);
                            if (props.links) {
                                return (
                                    <Link key={i} to={`/shop?top=1`}>
                                        <div className="prom-holder" onClick={() => viewProm(prom.id)} style={{borderRadius: 5, overflow: 'hidden', cursor:"pointer", position:'relative'}}>
                                            <img alt={prom.name} onClick={() => viewProm(prom.id)} style={{cursor:"pointer"}} src={small ? prom.smImage : prom.lgImage} />
                                            {
                                                message && <div style={{backgroundColor:'red', position:'absolute', top:0, right: 0, left:0, padding: 20, color:"white", fontSize: '1.1em'}}>{message}</div>
                                            }
                                        </div>
                                    </Link>
                                )
                            }
                            return (
                                <div className="prom-holder" key={i} onClick={() => viewProm(prom.id)} style={{borderRadius: 5, overflow: 'hidden', cursor:"pointer", position:'relative'}}>
                                    <img alt={prom.name} onClick={() => viewProm(prom.id)} style={{cursor:"pointer"}} src={small ? prom.smImage : prom.lgImage} />
                                    {
                                        message && <div style={{backgroundColor:'red', position:'absolute', top:0, right: 0, left:0, padding: 20, color:"white", fontSize: '1.1em'}}>{message}</div>
                                    }
                                </div>
                            )
                        })
                }

            </Carousel>
        </div>
    )
}

export default PromCaro;