import React from 'react';
import {
    Link,
    NavLink
} from 'react-router-dom';
import { FaUser, FaShoppingBasket } from 'react-icons/fa'
import { BasketContext } from './index';
import { AuthContext } from '../auth';

const BasketBar = props => {
    const { actions: {getCount }} = React.useContext(BasketContext);
    const { isLoggedIn } = React.useContext(AuthContext);

    const count = getCount();
    if (!isLoggedIn) return null;
    if (count === 0) return null;
    
    return (
        <div className='basket-bar-mobile'>
                <NavLink to="/checkout">
                    <div style={{display:'flex', flexDirection:'row', alignItems:'center', cursor:'pointer'}}>
                        <FaShoppingBasket color="rgb(70, 70, 70)" size={28} />
                        <span style={{marginLeft: 10, color: 'rgb(70, 70, 70)'}}>{getCount()} items</span>
                    </div>
                </NavLink>
        </div>
    )
}

export default BasketBar;