import React, { useContext } from 'react';
import Modal from 'react-modal';
import { AuthContext } from './index';
import * as Api from '../../api';
import { modalStyle } from '../modalStyle';

const ModalContent = props => {
    const { languageIsPt: pt, actions: { setForgotModalShowing, setLoginModalOpen } } = useContext(AuthContext);
    const [username, setUsername] = React.useState("");
    const [didSubmit, setDidSubmit] = React.useState(false)

    const submit = async () => {
        await Api.Post("/forgot", username);
        setDidSubmit(true);
    }

    if (didSubmit) {
        return (
            <div id="forgotModal">
                <h2>Thanks</h2>
                <p>{pt ? 'Se já possui conta, receberá um email com um link para repor a sua password. A ligação através do link expirará em duas horas' : 'If you have an account, you will receive and email with a link to reset your password. The link expires in 2 hours'}</p>
                <div style={{display:'flex', flexDirection:'row', alignSelf:'stretch', justifyContent:'flex-end'}}>
                    <div onClick={() => setForgotModalShowing(false)} className="button">OK</div>
                </div>
            </div>
        )
    }

    return (
        <div id="forgotModal">
            <h2>{pt ? 'Esqueci-me da password' : 'Forgot your Password?'}</h2>

            <p>{pt ? 'Por favor introduza os eu nome de usuário e carregue em submeter' : 'Please enter your username and press submit'}</p>

            <input className="email" type="text" value={username} placeholder="Username" style={{marginBottom: 10}} onChange={e => setUsername(e.target.value)} />
            <div className="between">
                <div onClick={() => {
                    setLoginModalOpen(true)
                    setForgotModalShowing(false)
                }} className="button cancel">
                    {pt ? 'Regressar a login' : 'Back to Login'}
                </div>
                <div onClick={submit} className="button large">
                    {pt ? 'Submeter' : 'Submit'}
                </div>
            </div>
        </div>
    )
}

const ForgotModal = props => {
    const { forgotModalShowing, actions: {setForgotModalShowing} } = useContext(AuthContext);

    return (
        <Modal
            isOpen={forgotModalShowing}
            onRequestClose={() => setForgotModalShowing(false)}
            style={modalStyle}
        >
            <ModalContent />
        </Modal>
    )
}

export default ForgotModal;