import React from 'react';
import { ProductsContext } from '../layers/products';

const Paginator = props => {
    const spread = 3;
    const { actions: {setPage}, currentQuery: {offset, limit: pageSize}, currentQueryCount: total } = React.useContext(ProductsContext)

    const beforeCurrent = (currentPage) => {
        const first = currentPage - spread;
        const out = [];
        for (let i=first; i < currentPage; i++) {
            if (i > 0) {
                out.push(i)
            }
        }
        return out;
    }
    const afterCurrent = (currentPage, lastPage, extendBy=0) => {
        const last = currentPage + spread + extendBy;
        const out = []
        for (let i=currentPage + 1; i <= last; i++) {
            if (i <= lastPage) {
                out.push(i);
            }
        }
        return out;
    }

    const buildArray = () => {
        let currentIndex = offset / pageSize;
        const lp = lastPage();
        const before = beforeCurrent(currentIndex + 1);
        const after = afterCurrent(currentIndex + 1, lp, spread-before.length)
        return before.concat([currentIndex + 1]).concat(after);
    }

    const lastPage = () => {
        return ((total - (total % pageSize)) / pageSize) + 1;
    }

    const showFirst = (arr) => {
        return arr[0] > 1;
    }

    const showLast = (arr, lst) => {
        return arr[arr.length -1] < lst;
    }

    const goTo = (p) => {
        setPage(p-1);
    }
    const current = offset / pageSize;
    const pageList = buildArray();
    const lp = lastPage();

    return (
        <div className="paginator">
        {
            showFirst(pageList) && <><div onClick={() => goTo(1)} className={`pageLink circle`}>1</div><div className="pageLink">...</div></>
        }
        {
            pageList.map((pg,i) => <div key={i} onClick={() => goTo(pg)} className={`circle pageLink${pg === current + 1 ? " active" : ""} circle`}>{pg}</div>)
        }
        {
            showLast(pageList, lp) && <><div className="pageLink">...</div><div onClick={() => goTo(lp)} className={`pageLink circle`}>{lp}</div></>
        }
        <span>{}</span>
    </div>
    )
}

export default Paginator;