import React from 'react';
import { AuthContext } from ".";
import PtFlag from '../../pt.png';
import UkFlag from '../../gb.png';
import { FiMenu } from 'react-icons/fi';

export const MenuBit = props => {
    const { languageIsPt: pt, mobileMenuOpen: open, actions: { setMobileMenuOpen }} = React.useContext(AuthContext);
    return (
        <div id='menu-icon-holder' onClick={() => setMobileMenuOpen(true)} style={{cursor:'pointer', position:'absolute', top: 10, left: 10}}>
            <FiMenu size={30} className="menu-icon" />
        </div>
    )
}

const LanguageBit = props => {
    const { languageIsPt, actions: { setLanguageIsPt }} = React.useContext(AuthContext);

    const getStyle = () => {
        let style = {};
        if (props.style) {
            style = props.style;
        }
        return style;
    }

    return (
        <div className={props.hideSmall ? 'language-bit-main' : 'language-bit'} onClick={() => setLanguageIsPt(!languageIsPt)} style={{cursor: 'pointer', position:'absolute', top: 10, left: 10, ...getStyle()}}>
            <img src={languageIsPt ? UkFlag : PtFlag} style={{width: 40, height: 30}} />
        </div>
    )
}

export default LanguageBit;