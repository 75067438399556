import React from 'react';
import * as Api from '../api';
import { AuthContext } from './auth';

export const PromotionsContext = React.createContext();

const PromotionsWrapper = props => {
    const authContext = React.useContext(AuthContext);
    return (
        <PromotionsLayer authContext={authContext}>
            {props.children}
        </PromotionsLayer>
    )
}

class PromotionsLayer extends React.Component {
    state = {
        promotions: {},
        affectedIds: [],
        map: {}
    }

    updatePromotion = async promotion => {
        const data = await Api.Post("/promotions/push", promotion);
        this.setState({
            ...data
        })
    }

    pushPromotion = promotion => {
        this.setState({promotions: {...this.state.promotions, [promotion.id]: promotion}})
    }

    componentDidUpdate(prevprops) {
        if (prevprops.authContext.isAdmin === false && this.props.authContext.isAdmin === true) {
            this.getPromotions();
        }
    }

    getPromotions = async () => {
        const data = await Api.Get("/promotions");
        this.setState({
            ...data
        });
    }

    componentDidMount() {
        this.getPromotions();
    }

    render() {
        return (
            <PromotionsContext.Provider value={{...this.state, actions: { updatePromotion: this.updatePromotion, pushPromotion: this.pushPromotion}}}>
                {this.props.children}
            </PromotionsContext.Provider>
        )
    }
}

export default PromotionsWrapper