import React, { useContext } from 'react';
import Modal from 'react-modal';
import { RepContext } from './index';
import { FaPlus, FaMinus } from 'react-icons/fa';

const ModalContent = props => {
    const { actions: {setCreateCustomerModalShowing, updateCustomer : submitCustomer} } = React.useContext(RepContext);
    const [customer, setCustomer] = React.useState({
        id: 0,
        name: '',
        emailAddress: '',
        company: '',
        phone: '',
        addressOne :'',
        addressTwo: '',
        addressThree: '',
        addressFour: '',
        postcode: '',
        adjustment: 1.00,
        username: '',
        frozen: false
    });
    const [error, setError] = React.useState('')
    const [hasChanged, setHasChanged] = React.useState(false)

    const updateCustomer = (updates) => {
        setHasChanged(true)
        setCustomer({...customer, ...updates})
    }

    const submit = () => submitCustomer(customer, (e) => setError(e), () => {
        setCreateCustomerModalShowing(false);
    })

    if (customer === null) return null;
    return (
        <div id="customerModal">
            <h1>{customer.name}</h1>
            <div style={{display: "flex", flexDirection:"row", flexWrap: 'wrap'}}>
                <div style={{display: "flex", flexDirection: 'column', width: 250, marginRight: 10}}>

                    <div style={{marginBottom: 10, display: 'flex', flexDirection:'column'}}>
                        <span>Customer Name</span>
                        <input onChange={e => updateCustomer({name: e.target.value})} defaultValue={customer.name} />
                    </div>

                    <div style={{marginBottom: 10, display: 'flex', flexDirection:'column'}}>
                        <span>Phone Number</span>
                        <input update={e => updateCustomer({phone: e.target.value})} defaultValue={customer.phone} />
                    </div>

                    <div style={{marginBottom: 10, display: 'flex', flexDirection:'column'}}>
                        <span>Email Address</span>
                        <input onChange={e => updateCustomer({emailAddress: e.target.value})} defaultValue={customer.emailAddress} />
                    </div>

                    <div style={{marginBottom: 10, display: 'flex', flexDirection:'column'}}>
                        <span>Company Name</span>
                        <input onChange={e => updateCustomer({company: e.target.value})} defaultValue={customer.company} />
                    </div>

                    <div style={{marginBottom: 10, display: 'flex', flexDirection:'column'}}>
                        <span>VatCode</span>
                        <input onChange={e => updateCustomer({vatCode: e.target.value})} defaultValue={customer.vatCode} />
                    </div>
                </div>

                <div style={{display: "flex", flexDirection: 'column', width: 250}}>
                    <div style={{marginBottom: 5, display: 'flex', flexDirection:'column'}}>
                        <span>Address 1</span>
                        <input onChange={e => updateCustomer({addressOne: e.target.value})} defaultValue={customer.addressOne} />
                    </div>
                    <div style={{marginBottom: 5, display: 'flex', flexDirection:'column'}}>
                        <span>Address 2</span>
                        <input onChange={e => updateCustomer({addressTwo: e.target.value})} defaultValue={customer.addressTwo} />
                    </div>
                    <div style={{marginBottom: 5, display: 'flex', flexDirection:'column'}}>
                        <span>Address 3</span>
                        <input onChange={e => updateCustomer({addressThree: e.target.value})} defaultValue={customer.addressThree} />
                    </div>
                    <div style={{marginBottom: 10, display: 'flex', flexDirection:'column'}}>
                        <span>Postcode</span>
                        <input onChange={e => updateCustomer({postcode: e.target.value})} defaultValue={customer.postcode} />
                    </div>
                    <div style={{marginBottom: 10, display: 'flex', flexDirection:'column'}}>
                        <span>Username</span>
                        <input onChange={e => updateCustomer({username: e.target.value})} defaultValue={customer.username} />
                    </div>
                </div>

            </div>
            <div style={{alignSelf:"stretch", marginTop: 10}}>
                <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                    <input onChange={e => updateCustomer({frozen: e.target.checked})} type="checkbox" defaultChecked={customer.frozen} />
                    <span>Frozen Products Access</span>
                </div>

                <div style={{display: 'flex', flexDirection:'row', alignItems:'center', marginTop: 10}}>
                    <span style={{marginRight: 10}}>Price Override</span>
                    <div onClick={() => updateCustomer({adjustment: customer.adjustment - 0.01})} style={{padding: 2, cursor: 'pointer'}}><FaMinus /></div>
                    <span style={{marginLeft: 4, marginRight: 4}}>{customer.adjustment.toLocaleString('pt-PT', {style:'currency', currency:'EUR'})}</span>
                    <div onClick={() => updateCustomer({adjustment: customer.adjustment + 0.01})} style={{padding: 2, cursor: 'pointer'}}><FaPlus /></div>  
                    <span style={{marginLeft: 30, color:'grey'}}>Eg: Base Price = 10,00€, Customer Price ${(10 * customer.adjustment).toLocaleString('pt-PT', {style:'currency', currency:'EUR'})}</span>
                </div>
            </div>
            <span style={{color:"red", marginTop: 10}}>{error}</span>
            <div style={{display: "flex", flexDirection:'row', justifyContent:"flex-end", alignItems:'center', marginTop: 10}}>
                <div onClick={() => setCreateCustomerModalShowing(false)} className="button cancel" style={{marginRight: 20}}>Cancel</div>
                <div onClick={hasChanged ? submit : () => {}} className={`button ${!hasChanged ? 'cancel' : ''}`}>Save</div>
            </div>
        </div>
    )
}

const modalStyle = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    }
}

const CreateCustomerModal = props => {
    const { showCreateCustomerModal, actions: { setCreateCustomerModalShowing } } = useContext(RepContext);

    return (
        <Modal
            isOpen={showCreateCustomerModal}
            onRequestClose={() => setCreateCustomerModalShowing(false)}
            style={modalStyle}
        >
            <ModalContent />
        </Modal>
    )
}

export default CreateCustomerModal;