import React from 'react';
import RCFooter from 'rc-footer';
import 'rc-footer/assets/index.css'; // import 'rc-footer/asssets/index.less';
import { AuthContext } from '../layers/auth';
import { Link } from 'react-router-dom';

const Footer = props => {
    const { languageIsPt: pt, actions : { setApplyModalShowing, setLoginModalOpen } } = React.useContext(AuthContext);

    return (
        <RCFooter
            maxColumnsPerRow={5}
            theme="light"
            style={{padding: 1}}
            columns={[
                {
                    title: 'Albany Portugal',
                    openExternal: false,
                    style: {color: 'black'},
                    items: [
                        {
                            title:<Link to="/shop">{pt ? 'Loja' : 'Shop'}</Link>,
                        },
                        {
                            title:<span style={{cursor:'pointer'}} onClick={e => {e.preventDefault(); e.stopPropagation(); setLoginModalOpen(true)}}>Login</span>
                        },
                        {
                            title:<span style={{cursor:'pointer'}} onClick={e => {e.preventDefault(); e.stopPropagation(); setApplyModalShowing(true)}}>{pt ? 'Registar-se' :'Apply for an Account'}</span>
                        }
                    ]
                },
                {
                    title: pt ? 'Nosso website' : "Our Website",
                    openExternal: false,
                    items: [
                        {
                            title: <Link to="/terms">{pt ? 'Termos & Condições' : 'Terms & Conditions'}</Link>
                        },
                        {
                            title: <Link to="/privacy">{pt ? 'Política de Privacidade' : 'Privacy Policy'}</Link>
                        }
                    ]
                }
            ]}
            bottom={
            <div className="address">
                <ul className="footer-list">
                    <li>Caminho do Cerro do Galo</li>
                    <li className="divide" />
                    <li>8135-028 Almancil</li>
                    <li className="divide hide-mobile" />
                    <li className='hide-mobile'>Portugal</li>
                    <li className="divide" />
                    <li>T: +351 915 416 200</li>
                </ul>
                <span style={{marginTop: 10, fontSize: '0.8em'}}>Copyright Portalbany Products Lda 2023</span>
            </div>
        }
            backgroundColor="#FAC043"
            
        />
    )
}
export default Footer