import moment from 'moment';
import React, { useContext } from 'react';
import Modal from 'react-modal';
import { ProductsContext } from '../products';
import { OrdersContext } from './index.js';
import { modalStyle } from '../modalStyle';
import { AuthContext } from '../auth';
import { FiPlusCircle, FiMinusCircle } from 'react-icons/fi';
import * as Api from '../../api';
import { downloadFile } from '../../utils';


const ModalContent = props => {
    const { viewingOrder, actions: {setViewingOrder} } = useContext(OrdersContext);
    const { productData, actions: { getProductData } } = useContext(ProductsContext);
    const { languageIsPt: pt } = React.useContext(AuthContext);
    const [labels, set_labels] = React.useState({});
    const order = viewingOrder;

    React.useEffect(() => {
        console.log(labels)
    }, [labels])

    const getLabels = async () => {
        try {
            const data = await Api.Post("/labels", labels, false, null);
            downloadFile("Labels.pdf", data);
        } catch(e) {
            console.log("FAILED", e);
        }
    }
    
    React.useEffect(() => {
        if (order !== null) {
            var productIds = [];
            var lbls = {};
            const kys = Object.keys(order.lineGroups)
            for ( var i=0; i < kys.length; i++ ) {
                const grp = order.lineGroups[kys[i]];
                if (grp === null) continue;
                for (var j=0; j < grp.length; j++) {
                    productIds.push(grp[j].productId);
                    lbls[grp[j].productId] = grp[j].quantity;
                }
            }
            getProductData(productIds);
            set_labels(lbls);
        }
    }, [order, getProductData]);

    const orderValue = () => {
        if (order === null) return 0;
        var value = 0;
        const groups = Object.values(order.lineGroups);
        for (var i=0; i < groups.length; i++) {
            const g = groups[i];
            if (g === null) continue;
            for (var j=0; j < g.length; j++) {
                value = value + g[j].price * g[j].quantity;
            }
        }
        return value;
    }

    const addLabel = (pid, q) => {
        let new_qty = (labels?.[pid] || 0) + q;
        if (new_qty < 0) {
            new_qty = 0;
        }
        set_labels({
            ...labels,
            [pid]: new_qty
        });
    }

    if (order === null) return null;


    return (
        <div id="viewOrderModal">
            <h2>Order {order.id}</h2>
            <span style={{fontSize: '1em'}}>{pt ? 'Encomenda realizada' : 'Placed on'} {moment(order.orderTime).format("Do MMMM YYYY")} at {moment(order.orderTime).format("HH:mm")} {order.byRep !== -1 && order.byRep !== order.userId ? ' by your rep' : ''}</span>
                <div style={{maxHeight:400, overflowY:'auto'}}>
                    <table className="order-view" style={{marginTop: 20, fontSize: '0.8em'}}>
                        <thead>
                            <tr>
                                <th>{pt ? 'Produto' : 'Product'}</th>
                                <th>{pt ? 'Tamanho' : 'Size'}</th>
                                <th>{pt ? 'Tamanho por caixa' : 'Case Size'}</th>
                                <th>{pt ? 'Quantidade' : 'Quantity'}</th>
                                <th>{pt ? 'Preço' : 'Price'}</th>
                                <th>Total</th>
                                <th>Labels</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Object.keys(order.lineGroups).map((group, i) => {
                                    const lines = order.lineGroups[group];
                                    if (lines === null) return null;
                                    return lines.map((line, i) => {
                                        const product = productData[line.productId] || {description:'Loading...', size:'...', case:'...'}
                                        return (
                                            <tr>
                                                <td>{product.description}</td>
                                                <td style={{paddingLeft: 10, paddingRight: 10}}>{product.size}</td>
                                                <td style={{paddingLeft: 10, paddingRight: 10}}>{product.case}</td>
                                                <td style={{paddingLeft: 10, paddingRight: 10}}>{line.quantity}</td>
                                                <td style={{paddingLeft: 10, paddingRight: 10}}>{line.price.toLocaleString('pt-PT', {style:'currency', currency:'EUR'})}</td>
                                                <td>{(line.quantity * line.price).toLocaleString('pt-PT', {style:'currency', currency:'EUR'})}</td>
                                                <td>
                                                    <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                                                        <div style={{cursor:'pointer'}} onClick={() => addLabel(product.id, -1)}>
                                                            <FiMinusCircle size={20} color="grey" />
                                                        </div>
                                                        <span style={{alignSelf:'start', justifyContent:'center', alignItems:'center', fontSize: 16, marginLeft: 5, marginRight: 5}}>{labels?.[product.id] || 0}</span>
                                                        <div style={{cursor:'pointer'}} onClick={() => addLabel(product.id, 1)}>
                                                            <FiPlusCircle size={20} color="grey" />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                })
                            }
                            <tr style={{fontWeight:'bold'}}>
                                <td>Total</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>{orderValue().toLocaleString('pt-PT', {style:'currency', currency:'EUR'})}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div style={{display:'flex', alignSelf:"stretch", flexDirection:'row', justifyContent:'flex-end', marginTop: 10}}>
                        <div style={{marginRight: 10}} onClick={getLabels} className="button">Download Labels</div>
                        <div onClick={() => setViewingOrder(null)} className="button">OK</div>
                    </div>
                </div>
        </div>
    )
}

const OrderModal = props => {
    const { viewingOrder, actions: { setViewingOrder }} = useContext(OrdersContext);

    return (
        <Modal
            isOpen={viewingOrder !== null}
            onRequestClose={() => setViewingOrder(null)}
            style={modalStyle}
        >
            <ModalContent />
        </Modal>
    )
}

export default OrderModal;