import React, { useContext } from 'react';
import Modal from 'react-modal';
import { AuthContext } from './index';
import * as Api from '../../api';
import { modalStyle } from '../modalStyle';

const blankApplication = {
    email: '',
    name: '',
    businessName: '',
    phone: '',
    website: '',
    addressOne: '',
    addressTwo: '',
    addressThree: '',
    postcode: '',
    vatNumber: '',
}

function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

const ModalContent = props => {
    const { languageIsPt: pt, actions: { setApplyModalShowing } } = useContext(AuthContext);
    const [didApply, setDidApply] = React.useState(false);
    const [app, setApp] = React.useState(blankApplication)
    const [error, setError] = React.useState('')

    const updateApplication = (updates) => {
        setApp({...app, ...updates})
    }

    const validate = () => {
        if (!validateEmail(app.email)) {
            return "Invalid Email Address"
        }
        if (app.name === '') {
            return "Please enter Your Name"
        }
        if (app.businessName === '') {
            return 'Please enter the name of your business'
        }
        if (app.phone === '') {
            return 'Please enter your phone number'
        }
        if (app.addressOne === '') {
            return 'Please enter you address'
        }
        if (app.postcode === '') {
            return 'Please enter your postcode'
        }
        return true
    }

    const submit = async () => {
        const isValid = validate();
        if (isValid !== true) {
            setError(isValid)
        } else {
            setError('')
            await Api.Post("/apply", app, false)
            setDidApply(true);
            setApp(blankApplication);
        }
    }

    if (didApply) {
        return (
            <div id="applyModal">
                <h2>Thanks</h2>
                <p>We've received your application, and one of our team will be in touch soon</p>
                <div style={{display:'flex', flexDirection:'row', alignSelf:'stretch', justifyContent:'flex-end'}}>
                    <div onClick={() => setApplyModalShowing(false)} className="button">OK</div>
                </div>
            </div>
        )
    }

    return (
        <div id="applyModal">
            <h2>{pt ? 'Novo registo de conta' : 'Apply for an Account'}</h2>
            <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
                <div style={{display:'flex', flexDirection:'column', marginRight: 20}}>
                    <div style={{display:'flex', flexDirection:'column', marginBottom: 5}}>
                        <span style={{fontSize: '0.8em'}}>{pt ? 'Endereço de e-mail' : 'Email Address'}</span>
                        <input className="email" type="email" value={app.email} style={{marginBottom: 10}} onChange={e => updateApplication({email: e.target.value})} />
                    </div>

                    <div style={{display:'flex', flexDirection:'column', marginBottom: 5}}>
                        <span style={{fontSize: '0.8em'}}>{pt ? 'Nome' : 'Contact Name'}</span>
                        <input className="email" type="text" value={app.name} style={{marginBottom: 10}} onChange={e => updateApplication({name: e.target.value})} />
                    </div>
                    

                    <div style={{display:'flex', flexDirection:'column', marginBottom: 5}}>
                        <span style={{fontSize: '0.8em'}}>{pt ? 'Nome Empresarial' : 'Business Name'}</span>
                        <input className="email" type="text" value={app.businessName} style={{marginBottom: 10}} onChange={e => updateApplication({businessName: e.target.value})} />
                    </div>
                    

                    <div style={{display:'flex', flexDirection:'column', marginBottom: 5}}>
                        <span style={{fontSize: '0.8em'}}>{pt ? 'Telefone' : 'Phone Number'}</span>
                        <input className="email" type="text" value={app.phone} style={{marginBottom: 10}} onChange={e => updateApplication({phone: e.target.value})} />
                    </div>
                    

                    <div style={{display:'flex', flexDirection:'column', marginBottom: 5}}>
                        <span style={{fontSize: '0.8em'}}>Website{!pt ? ' URL' : ''}</span>
                        <input className="email" type="text" value={app.website} style={{marginBottom: 10}} onChange={e => updateApplication({website: e.target.value})} />
                    </div>
                </div>

                <div style={{display:'flex', flexDirection:'column'}}>
                    <div style={{display:'flex', flexDirection:'column', marginBottom: 5}}>
                        <span style={{fontSize: '0.8em'}}>{pt ? 'Endereço 1' : 'Address Line 1'}</span>
                        <input className="email" type="text" value={app.addressOne} style={{marginBottom: 10}} onChange={e => updateApplication({addressOne: e.target.value})} />
                    </div>

                    <div style={{display:'flex', flexDirection:'column', marginBottom: 5}}>
                        <span style={{fontSize: '0.8em'}}>{pt ? 'Endereço 2' : 'Address Line 2'}</span>
                        <input className="email" type="text" value={app.addressTwo} style={{marginBottom: 10}} onChange={e => updateApplication({addressTwo: e.target.value})} />
                    </div>

                    <div style={{display:'flex', flexDirection:'column', marginBottom: 5}}>
                        <span style={{fontSize: '0.8em'}}>{pt ? 'Endereço 3' : 'Address Line 3'}</span>
                        <input className="email" type="text" value={app.addressThree} style={{marginBottom: 10}} onChange={e => updateApplication({addressThree: e.target.value})} />
                    </div>

                    <div style={{display:'flex', flexDirection:'column', marginBottom: 5}}>
                        <span style={{fontSize: '0.8em'}}>{pt ? 'Código Postal' : 'Postcode'}</span>
                        <input className="email" type="text" value={app.postcode} style={{marginBottom: 10}} onChange={e => updateApplication({postcode: e.target.value})} />
                    </div>

                    <div style={{display:'flex', flexDirection:'column', marginBottom: 5}}>
                        <span style={{fontSize: '0.8em'}}>{pt ? 'NIF' : 'VAT Code'}</span>
                        <input className="email" type="text" value={app.vatNumber} style={{marginBottom: 10}} onChange={e => updateApplication({vatNumber: e.target.value})} />
                    </div>
                </div>
            </div>
            <div style={{alignSelf:'stretch', padding: 10, color:"red"}}>
                {error}
            </div>
            <div style={{alignSelf:"stretch", flexDirection:'row', display:'flex', justifyContent:'space-between'}}>
                <div onClick={() => setApplyModalShowing(false)} className="button cancel">{pt ? 'Cancelar' : 'Cancel'}</div>
                <div onClick={submit} className="button">{pt ? 'Enviar' : 'Apply'}</div>
            </div>
        </div>
    )
}

const ApplyModal = props => {
    const { applyModalShowing, actions: { setApplyModalShowing } } = useContext(AuthContext);

    return (
        <Modal
            isOpen={applyModalShowing}
            onRequestClose={() => setApplyModalShowing(false)}
            style={modalStyle}
        >
            <ModalContent />
        </Modal>
    )
}

export default ApplyModal;