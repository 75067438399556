export const modalStyle = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: 20
    },
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.7)',
        zIndex: 20
    }
}